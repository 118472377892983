@use "./variables" as *;
/* ##############################################################################

    KEYFRAMES

############################################################################## */

@keyframes hero_slide {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}

@keyframes hero_dots_dot {
  from {
    transform: scale(1);
    opacity: 0.5;
  }
  to {
    transform: scale(3.5);
    opacity: 0;
  }
}

@keyframes hero_dots_line {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes dots_light {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  80% {
    opacity: 0.4;
  }
  100% {
    transform: scale(4.5);
    opacity: 0;
  }
}
@keyframes deco_balloon {
  0% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}
@keyframes deco_cloud {
  0% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(-10px);
  }
}
