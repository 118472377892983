@use "../abstracts" as *;

/* lps_parts--slide
********************************************** */
.slick-arrow {
  position: absolute;
  top: calc(50% - max(vw(72), 40px) / 2);
  z-index: 1;
  display: grid;
  place-items: center;
  width: max(vw(72), 40px);
  height: max(vw(72), 40px);
  color: var(--clr-wht);
  background-color: color("main", 200);
  border-radius: 50%;
  cursor: pointer;
  &::before {
    content: "";
    width: 9px;
    height: 21px;
    border-top: 1px solid;
    border-left: 1px solid;
    background-color: var(--bg-wht);
    clip-path: polygon(10% 0, 100% 50%, 10% 100%, 0% 100%, 90% 50%, 0% 0%);
    @include mq(sp) {
      width: 7px;
      height: 12px;
    }
  }
}
.slick-prev {
  left: calc(max(vw(72), 40px) / -2);
  &::before {
    transform: scale(-1, 1);
  }
}
.slick-next {
  right: calc(max(vw(72), 40px) / -2);
}

.lps_parts--slide .slick-arrow {
  top: calc(var(--height) / 2 - max(vw(72), 40px) / 2);
}
.lps_parts--slide .img img {
  display: inline-block;
}
.lps_parts--slide .img + .text {
  margin-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  @include mq(med) {
    margin-top: 16px;
  }
}
.lps_parts--slide .slick-list {
  margin-bottom: 32px;
}
.lps_parts--slide .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @include mq(med) {
    position: relative;
    bottom: 4px;
  }
}
.lps_parts--slide .slick-dots li {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #ddd;
  transform: rotate(45deg);
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
  margin: 6px;
}
.lps_parts--slide .slick-dots li.slick-active {
  background-color: var(--bg-main);
}
.lps_parts--slide .slick-dots li:hover {
  opacity: 0.7;
}
