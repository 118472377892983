@use "../abstracts" as *;

/* contact
********************************************** */
.cta_tel {
  width: fit-content;
  min-width: min(600px, 100%);
  margin-inline: auto;
  text-align: center;
  @include mq(sp) {
    padding: spvw(16);
  }
}
.cta_tel a {
  text-align: center;
  line-height: 1.4;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
  }
}
.cta_tel .tel {
  display: inline-block;
  font-size: rem(18);
  font-family: var(--font-en2), serif;
}
.cta_tel .num {
  font-size: rem(36);
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.cta_tel .hours {
  display: block;
  width: 100%;
}
.contact_list--ttl {
  margin-bottom: 1em;
  font-size: rem(24);
}
.contact_list {
  display: grid;
  gap: vw(32);
  margin-bottom: vw(160);
  @include mq(sp, min) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.contact_list li {
  position: relative;
  z-index: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: vw(50) vw(24);
  background-color: #aba783;
  @supports (grid-template-rows: subgrid) {
    display: grid;
    grid-row: span 3;
    grid-template-rows: subgrid;
  }
  @include mq(sp) {
    padding: spvw(30);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    pointer-events: none;
  }
  &::before {
    inset: 5px;
    border: 1px solid color("main", 500);
  }
  &::after {
    inset: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0);
    transition: var(--transit-default);
  }
  &:hover::after {
    background-color: rgba(0, 0, 0, 0.7);
  }
  &:hover .contact_list--bg {
    opacity: 1;
  }
}
.contact_list--bg {
  position: absolute;
  z-index: -1;
  inset: 0;
  background-image: var(--bg);
  transition: var(--transit-default);
  pointer-events: none;
}
.contact_list .btn {
  margin-top: auto;
}
.contact_list .btn a {
  background: none !important;
  &::after {
    content: "";
    position: absolute;
    inset: -100vw;
  }
}
