@use "../abstracts" as *;

/* Wysiwyg editor
 **************************************** */
.mce-content-body ol {
  list-style: decimal;
}
.mce-content-body ul:not(:first-child),
.mce-content-body ol:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body ul:not(:last-child),
.mce-content-body ol:not(:last-child) {
  margin-bottom: 1em;
}
.mce-content-body ul li,
.mce-content-body ol li {
  position: relative;
  margin: 0;
  border-bottom: none;
  padding: 0;
  margin-left: 2em;
  padding-top: 0.5em;
}
.mce-content-body ul li {
  list-style: none;
}
.mce-content-body ul li::before {
  content: "";
  position: absolute;
  top: 1em;
  left: -1.3em;
  @include rect(10);
  border: 1px solid var(--clr-main);
  background-color: var(--clr-main);
}
.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: #fff;
}
.mce-content-body ul:not([style="list-style-type: square;"]) li::before {
  border-radius: 50%;
}
.mce-content-body img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}
.mce-content-body hr {
  border-color: var(--border-main);
}
.box {
  padding: 24px 32px;
  border: 1px solid var(--border-main);
}
.box:not(:first-child) {
  margin-top: 2em;
}
.box:not(:last-child) {
  margin-bottom: 2em;
}
.box-wh {
  background-color: var(--bg-wht);
  background-image: url(../images/common/ptn03.jpg);
  box-shadow: 0 0 24px rgba(93, 93, 93, 0.05);
  border: 0;
}
.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}
.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}
.mce-content-body .aligncenter {
  @include auto-margin;
  margin-bottom: 1em;
}
.mce-content-body img.aligncenter {
  display: block;
}
.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}
.mce-content-body p {
  letter-spacing: 0.1em;
}
.mce-content-body p:not(:last-child) {
  margin-bottom: 1em;
}
.mce-content-body strong {
  font-weight: bold;
}
.mce-content-body blockquote {
  margin: 1em 0;
  padding: 15px 40px;
  border-radius: 3px;
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: #666;
  background-color: #f8f8f8;
}
.mce-content-body blockquote p {
  margin-bottom: 0;
}
.mce-content-body blockquote::before,
.mce-content-body blockquote::after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  opacity: 0.25;
}
.mce-content-body blockquote::before {
  background-image: url("../images/common/icon-quote_left.svg");
  top: 13px;
  left: 10px;
}
.mce-content-body blockquote::after {
  background-image: url("../images/common/icon-quote_right.svg");
  bottom: 13px;
  right: 10px;
}
.mce-content-body a {
  text-decoration: underline;
}
.mce-content-body a:hover {
  text-decoration: none;
}
.mce-content-body .footer_text small {
  display: block;
  margin-top: 8px;
  text-align: right;
}
.mce-content-body table {
  border: none;
  height: auto !important;
}
.mce-content-body table:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body table:not(:last-child) {
  margin-bottom: 1em;
}
table {
  width: 100%;
}
table th,
table td,
table tr {
  height: auto !important;
}
table th,
table td {
  padding: 1em;
  vertical-align: top;
  @include mq(sp) {
    padding: 0.5em 1em;
  }
}
table th {
  min-width: 100px;
  text-align: left;
}
table td {
  background-color: var(--bg-wht);
  background-image: url(../images/common/ptn03.jpg);
}
table th,
table td {
  border: 1px solid var(--border-sub);
}
.mce-content-body sup {
  font-size: rem(12);
  color: #555;
}
.bold {
  font-weight: bold;
  color: var(--clr-main);
}

/* iframe */
iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  vertical-align: bottom;
}
.mce-content-body iframe:not(:first-child) {
  margin-top: 2em;
}
.mce-content-body iframe:not(:last-child) {
  margin-bottom: 2em;
}
.iframe--youtube img {
  aspect-ratio: 16/9;
}

/* table scroll */
.table-sp-scroll {
  white-space: nowrap;
}
.scroll {
  overflow-x: scroll;
  margin-bottom: 1em;
  -webkit-overflow-scrolling: touch;
}
.scroll table {
  margin: 0;
}
.scroll--cap {
  text-align: right;
  margin: 1em 0 0 !important;
  opacity: 0.25;
  font-size: rem(14);
}

@include mq(sp) {
  .mce-content-body p:not([class]) {
    text-align: left !important;
  }
  .mce-content-body .alignright,
  .mce-content-body .alignleft {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }
  .mce-content-body blockquote {
    padding: 15px;
  }
  .mce-content-body blockquote::before {
    top: 0;
    left: 0;
  }
  .mce-content-body blockquote::after {
    bottom: 0;
    right: 0;
  }
  .mce-content-body ul li::before {
    @include rect(8);
  }
  .mce-content-body table {
    max-width: none !important;
  }
  .mce-content-body th {
    font-weight: bold;
  }
  .mce-content-body th,
  .mce-content-body td {
    padding: 0.5em 1em;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-content-body .box {
    padding: 24px;
  }
}
/* 管理画面エディタ用
*************************************************** */
.mce-content-body.wp-editor,
.mce-content-body.wp-editor * {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
}
.mce-content-body.wp-editor {
  padding: 24px !important;
}

@include mq(sp) {
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2),
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}
/* add quicktag
*************************************************** */
/* 見出し */
.editor-ttl:not(:first-child),
.editor-ttl2:not(:first-child),
.editor-ttl3:not(:first-child) {
  margin-top: 1em;
}
.editor-ttl:not(:last-child),
.editor-ttl2:not(:last-child),
.editor-ttl3:not(:last-child) {
  margin-bottom: 1em;
}
.editor-ttl {
  font-size: rem(32);
  font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  -webkit-font-smoothing: antialiased;
  @include lh(32, 48);
  letter-spacing: 0.2em;
  &::after {
    content: "";
    display: block;
    width: rem(80);
    height: 1px;
    margin-top: 0.7em;
    background-color: var(--border-main);
    @include mq(sp) {
      width: sprem(56);
    }
  }
  @include mq(sp) {
    font-size: sprem(20);
    @include lh(20, 30);
  }
  .editor-ttl[style="text-align: center;"]::after {
    margin-inline: auto;
  }
}
.editor-ttl2 {
  color: var(--clr-main);
  font-size: rem(26);
  font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  -webkit-font-smoothing: antialiased;
  @include lh(26, 42);
  letter-spacing: 0.2em;
  @include mq(sp) {
    font-size: sprem(18);
    @include lh(18, 27);
  }
}
.editor-ttl3 {
  font-size: rem(20);
  font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  -webkit-font-smoothing: antialiased;
  @include lh(20, 35);
  letter-spacing: 0.2em;
  @include mq(sp) {
    font-size: sprem(16);
    @include lh(16, 24);
  }
}

/* 注意書き */
.txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: rem(14);
  color: #999;
  @include mq(sp) {
    font-size: sprem(13);
  }
  &::before {
    content: "※";
  }
}

/* テーブル */
.mce-content-body table:not(.table1) th,
.table2 th {
  background-color: var(--clr-main);
  color: var(--clr-wht, #fff);
}
.table1 th,
.table1 td {
  position: relative;
  border: 0;
  padding: 1em 1.5em;
}
.table1 tr {
  border-bottom: 1px solid var(--border-main);
  &:first-child {
    border-top: 1px solid var(--border-main);
  }
}
.table1 th {
  color: var(--clr-main);
  background-color: rgba(200, 196, 166, 0.3);
  @include mq(sp, min) {
    font-size: rem(18);
  }
  @include mq(sp) {
    padding: 0.5em 1em;
  }
}
@include mq(sp) {
  .table1.table-sp-block td {
    padding: 0.5em 2em;
  }
}
.page-guide {
  .mce-content-body table td:last-child {
    font-size: rem(12);
  }
  .mce-content-body table th,
  .mce-content-body table td {
    padding: 0.3em 1em;
    font-size: rem(14);
    letter-spacing: 0.05em;
    text-align: initial;
    white-space: normal;
    min-width: 160px;
  }
  @include mq(sp, min) {
    .mce-content-body table {
      width: 100% !important;
    }
    .mce-content-body table tr:first-child th:first-child {
      width: 300px;
    }
    .mce-content-body table tr:first-child th:nth-child(2) {
      width: 90px;
      min-width: auto;
    }
    .mce-content-body table tr:first-child th:nth-child(3) {
      width: 220px;
    }
    .mce-content-body table tr:first-child th:nth-child(4) {
      width: 120px;
    }
    .mce-content-body table tr:first-child th:nth-child(5) {
      width: 280px;
    }
  }
}

@include mq(sp) {
  /* テーブル */
  .table2.table-sp-block th,
  .table2.table-sp-block td {
    border-bottom: 0;
  }
  .table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: 1px solid #d3d3d3;
  }
  .table1.table-sp-block td::before {
    display: none;
  }
}
