@use "../abstracts" as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

/* post
**************************************** */
.post {
  position: relative;
}
.post--img {
  overflow: hidden;
  background-color: var(--clr-body);
}
.post--img img {
  transition: var(--transit-default);
}
.post--img .img-contain {
  max-width: 80%;
}
.post:hover img {
  transform: scale(1.05);
  opacity: 0.5;
}
.post--link::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 1;
  cursor: pointer;
}
.post--date {
  color: var(--clr-main);
  font-size: rem(14);
  font-family: var(--font-en2), serif;
  line-height: var(--line-height-none);
}
.post--info {
  gap: 1rem;
  margin-bottom: 0.5rem;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
  flex-shrink: 0;
}
.cat_list a {
  display: block;
  padding: 0.3em 1em;
  color: #fff;
  font-size: rem(14);
  line-height: 1;
  background-color: var(--clr-main, #1d7fb7);
  vertical-align: middle;
}
.cat_list a:hover {
  opacity: 0.7;
}
.tag_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.tag_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: rem(14);
  line-height: var(--line-height-none);
  white-space: nowrap;
  margin: 2px;
  &::before {
    content: "#";
  }
}
.tag_list a:hover {
  opacity: 0.7;
}

/* side_column
**************************************** */
/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
  margin-top: rem(30);
}
.archive-pulldown .archive_list {
  position: relative;
}
.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 24px;
}
.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
}
.archive-pulldown .archive_list a:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.archive-pulldown .archive_list a:hover {
  background-color: #eee;
}
.archive-pulldown .archive_list--label {
  margin-right: 8px;
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.25em 4em 0.25em 2em;
  background-color: #fff;
  border: 1px solid #ccc;
  color: inherit;
  font-family: var(
    --font-primary,
    "YuGothic",
    "游ゴシック",
    "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic ProN",
    sans-serif
  );
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: 0.08em;
}
.archive-pulldown .archive_list--btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0.4em;
  right: 0.8em;
  margin: auto;
  width: 0.6em;
  height: 0.6em;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}
.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}

/* news
**************************************** */
.news_list .post {
  display: grid;
  grid-template-columns: max(vw(220), 100px) 1fr;
  gap: rem(40);
  padding-block: rem(26);
  border-bottom: 1px solid var(--border-main);
  @include mq(sp) {
    gap: sprem(16);
    padding-block: sprem(18);
  }
  &:first-child {
    border-top: 1px solid var(--border-main);
  }
}
.news_list .post--link:not([href="javascript:void(0);"]):hover,
.news_list .post--link:hover svg {
  opacity: 0.5;
}
.news_list .post--txtarea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
}
.news_list .post--date {
  color: #c7c39e;
  font-size: rem(14);
  letter-spacing: 0.06em;
}
.news_list .post--ttl {
  margin-bottom: 0.5em;
  font-size: rem(20);
  letter-spacing: 0.2em;
  @include mq(sp) {
    font-size: sprem(15);
  }
}
.news_list .post--txt {
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.08em;
  @include lh(15, 22);
  @include mq(sp) {
    font-size: sprem(11);
  }
}
.home {
  .news_list .post,
  .news_list .post--link {
    color: var(--clr-wht);
  }
  .news_list .post--txt {
    color: rgba(255, 255, 255, 0.6);
  }
  .news_list .cat_list a {
    color: var(--clr-body);
    background-color: var(--bg-wht);
  }
  .news_list .post {
    border-color: rgba(255, 255, 255, 0.4);
  }
}

// tax_list--list
.tax_list--list {
  margin-bottom: 1rem;
  a {
    display: block;
    padding: 0.5em 1em;
    font-size: 14px;
    line-height: var(--line-height-none);
    background-color: var(--bg-wht);
    border: 1pd solid var(--bg-main);
  }
  .current-cat a {
    color: var(--clr-wht);
    background-color: var(--bg-main);
  }
  .swiper-container {
    position: relative;
  }
  .swiper-slide {
    width: fit-content;
  }
  .swiper-button-next,
  .swiper-button-prev {
    cursor: pointer;
    &::after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      color: var(--clr-body);
      @include mq(sp) {
        width: 16px;
        height: 16px;
      }
    }
  }
  .swiper-button-next {
    right: -30px;
    @include mq(sp) {
      right: -20px;
    }
    &::after {
      border-top: 3px solid;
      border-right: 3px solid;
      transform: rotate(45deg);
    }
  }
  .swiper-button-prev {
    left: -30px;
    @include mq(sp) {
      left: -20px;
    }
    &::after {
      border-bottom: 3px solid;
      border-left: 3px solid;
      transform: rotate(45deg);
    }
  }
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0.15;
  }
}

/* --- news--archive --- */
.news--archive {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: #fff;
}

/* post-default
**************************************** */
.post .event_dates {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.5em;
  color: var(--clr-wht);
  font-size: rem(15);
  line-height: var(--line-height-none);
  background-color: var(--bg-main);
}
.post .event_dates svg {
  width: 16px;
  height: 17px;
  fill: var(--bg-wht);
}
.posts-default {
  display: flex;
  flex-wrap: wrap;
}
.posts-default .post--img {
  margin-bottom: 1rem;
}
.end_event {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: rem(24);
}
.posts-default .post--ttl {
  font-size: rem(24);
  @include mq(sp) {
    font-size: sprem(16);
  }
}
.posts-default .post--yomigana {
  margin-top: 1em;
  font-size: rem(13);
  line-height: 1.2;
}
.archive {
  .inner-lg {
    max-width: rem(1600);
  }
  .posts-default {
    display: grid;
    gap: vw(29);
    @include mq(sp, min) {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
    @include mq(sp) {
      grid-template-columns: 1fr 1fr;
      grid-column-gap : sprem(8);
      grid-row-gap : sprem(20);
    }
  }
  .posts-default .post {
    position: relative;
    padding: rem(12);
    border: 1px solid var(--border-main);
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: calc(50% - rem(10) / 2);
      width: rem(10);
      height: rem(10);
      background-color: #c0bda1;
      transform: rotate(45deg);
    }
    &::before {
      top: calc(rem(-10) / 2);
    }
    &::after {
      bottom: calc(rem(-10) / 2);
    }
  }
}

// search_box
.search_box {
  position: relative;
  margin-top: calc(rem(130) / 2 + vw(100));
  margin-bottom: vw(72);
  padding: vw(80) vw(120);
  box-shadow: 0 0 10px rgb(69 69 69 / 10%);
  @include mq(sp) {
    margin-bottom: spvw(36);
    padding: spvw(40) spvw(20) spvw(20);
  }
}
.term_box:not(:last-child) {
  margin-bottom: vw(64);
  @include mq(sp) {
    margin-bottom: spvw(32);
  }
}
.search_box--ttl {
  position: absolute;
  top: calc(rem(-130) / 2);
  left: calc(50% - rem(130) / 2);
  z-index: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: rem(16);
  width: rem(130);
  height: rem(130);
  padding-top: 2rem;
  color: var(--clr-wht);
  font-size: rem(12);
  font-weight: normal;
  letter-spacing: 0.16em;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--bg-main2);
    background-image: url(../images/common/ptn04.jpg);
  }
  svg {
    display: block;
    width: rem(24);
    height: auto;
    fill: var(--bg-wht) !important;
  }
}
.term_box--ttl {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 1em;
  padding-bottom: 0.5em;
  color: var(--clr-wht);
  font-size: rem(26);
  letter-spacing: 0.2em;
  border-bottom: 1px solid var(--border-main);
  @include mq(sp) {
    font-size: sprem(18);
  }
  &::before,
  &::after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    transform: rotate(45deg);
    background-color: var(--bg-wht);
  }
}
.term_box--lists {
  position: relative;
  .current-cat a {
    color: var(--clr-wht);
    background-color: var(--bg-main);
  }
}
.term_box--lists a {
  display: inline-block;
  padding: 0.5em 1.3em;
  line-height: var(--line-height-hdr);
  background-color: var(--bg-wht);
  border: 1px solid var(--border-main);
  @include mq(sp) {
    padding: 0.3em 1em;
    font-size: sprem(12);
  }
  &::before {
    content: "#";
  }
}
.term_box--lists {
  .swiper-container {
    overflow: hidden;
  }
  .swiper-slide {
    width: auto;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: fit-content;
    &::after,
    &::after {
      color: transparent;
      width: rem(20);
      height: rem(20);
      display: block;
      rotate: 45deg;
      @include mq(sp) {
        width: sprem(10);
        height: sprem(10);
      }
    }
  }
  .swiper-button-prev {
    left: rem(-50);
    @include mq(sp) {
      left: sprem(-13);
    }
    &::after {
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
    }
  }
  .swiper-button-next {
    right: rem(-50);
    @include mq(sp) {
      right: sprem(-13);
    }
    &::after {
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
    }
  }
}

/* search
**************************************** */
.search_list .post {
  gap: rem(30);
  padding-block: 1em;
  border-bottom: 2px solid var(--border-main);
  @include mq(sp) {
    gap: sprem(16);
    border-width: 1px !important;
  }
  &:first-child {
    border-top: 2px solid var(--border-main);
  }
}
.search_list .cat_list a {
  width: rem(144);
  text-align: center;
  @include mq(sp) {
    width: 7rem;
    padding: 0.3em;
    font-size: sprem(10);
    letter-spacing: 0.1em;
  }
}
.search_list .post--date {
  font-size: rem(13);
}
.search_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--bg-blue);
}
.search_list .post--link:hover svg {
  fill: var(--bg-blue);
}
.search_list .post--info {
  gap: rem(16);
}
.search_list .post--ttl {
  display: flex;
  align-items: center;
  font-size: rem(20);
  @include mq(sp) {
    font-size: sprem(14);
  }
}
.search_list .post--txtarea {
  width: 100%;
}

.sort_date_tab li {
  margin-left: rem(8);
}
.sort_date_tab li span {
  display: inline-block;
  background-color: #fff;
  border-radius: 99px;
  padding: 0 rem(16);
  cursor: pointer;
  transition: all .4s ease-out;
  letter-spacing: .05em;
  font-size: rem(15);
}
.sort_date_tab li.active span {
  background-color: #92835a;
  color: #fff;
}
.posts_sort {
  display: none;
}
.posts_sort.active {
  display: block;
}