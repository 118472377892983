@use "../abstracts" as *;

/* ##############################################################################

    Loops モジュール

############################################################################## */
.btn .icon-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transit-default, all 0.4s ease-out);
}
.icon-new_tab {
  @include rect(14);
}
.btn .icon-new_tab {
  right: rem(30);
}
.icon-pdf {
  @include rect(27, 12);
}
.btn .icon-pdf {
  right: rem(15);
}
.lps_sec {
  position: relative;
  &:first-child {
    padding-top: 0;
  }
  > .inner,
  > .lps_parts {
    position: relative;
    z-index: 2;
  }
}

// sec_deco
.sec_deco {
  position: relative;
}
.sec_deco::after,
.lps_sec:not(:last-child)::after {
  content: "";
  position: absolute;
  inset-inline: 0;
  z-index: 1;
  width: 100vw;
  background-size: 100%;
  pointer-events: none;
}
.sec_deco-btm01::after,
.lps_sec:nth-child(2n + 1)::after {
  bottom: calc((236 / 1920) * -100vw);
  background-image: url(../images/common/section-parts01.png);
  aspect-ratio: 1920/742;
  @include mq(sp) {
    bottom: calc(((193 / 2) / 414) * -100vw);
    background-image: url(../images/common/section-parts01-sp.png);
    aspect-ratio: 828/550;
  }
}
.sec_deco-btm02::after,
.lps_sec:nth-child(2n)::after {
  bottom: calc(((238 / 1920) * 100) * -1vw);
  background-image: url(../images/common/section-parts02.png);
  aspect-ratio: 1920/473;
  @include mq(sp) {
    bottom: calc(((193 / 2) / 414) * -100vw);
    background-image: url(../images/common/section-parts02-sp.png);
    aspect-ratio: 828/363;
  }
}

// lps_sec-deco
.lps_sec-deco {
  position: absolute;
  top: vw(465);
  bottom: 0;
  background-size: 100%;
  pointer-events: none;
}
.lps_sec:first-child .lps_sec-deco {
  top: vw(-246);
}
.lps_sec-deco-lft {
  left: 0;
  width: vw(482);
  max-width: 482px;
  background-image: url(../images/common/bg-lft.png);
}
.lps_sec-deco-rgt {
  right: 0;
  width: vw(596);
  max-width: 596px;
  background-image: url(../images/common/bg-rgt.png);
}

.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

/* ttl
********************************************** */
.lps_sec .ttl-01 {
  text-align: center;
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
}
.lps_parts + .ttl-02,
.lps_parts--button + .ttl-02,
.lps_parts--button + .lps_parts,
.lps_parts + .lps_parts {
  margin-top: 80px;
  @include mq(sp) {
    margin-top: 40px;
  }
}
.lps_parts--column + .lps_parts--column,
.lps_parts + .lps_parts--button {
  margin-top: 40px;
  @include mq(sp) {
    margin-top: 16px;
  }
}
.lps_parts--button + .lps_parts--button {
  margin-top: 0;
  @include mq(sp) {
    margin-top: 8px;
  }
}
.lps_parts iframe {
  width: 100%;
}
.lps_parts .text:not(:last-child) {
  margin-bottom: 24px;
}
.lps_parts--column .btn,
.lps_parts--slide .btn {
  text-align: center;
}
.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}
.lps_parts--column .btn a {
  @include auto-margin;
}
.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: 40px;
  @include mq(sp) {
    margin-bottom: 16px;
  }
}
@include mq(sp) {
  .lps_parts .btn {
    text-align: center;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
}
