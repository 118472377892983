@use "../abstracts" as *;

/* lps_parts--flow
********************************************** */
.lps_parts--flow .flow_item {
  position: relative;
  max-width: 860px;
  @include auto-margin;
  padding-left: 5.5rem;
  counter-increment: number;
  @include mq(sp) {
    padding-left: 48px;
  }
}
.lps_parts--flow .flow_item:not(:last-child) {
  padding-bottom: 40px;
  @include mq(sp) {
    padding-bottom: 20px;
  }
}
.lps_parts--flow .ttl-03 {
  color: var(--clr-main);
  font-size: rem(30);
  font-weight: normal;
  @include mq(sp) {
    font-size: sprem(20);
  }
  &::after {
    content: counter(number);
    position: absolute;
    top: -0.5rem;
    left: -5.2rem;
    display: flex;
    @include center-flex;
    @include rect(64);
    border-radius: 50%;
    text-align: center;
    color: #fff;
    background-color: var(--clr-main);
    font-family: var(--font-en2), serif;
    font-size: rem(26);
    letter-spacing: 0;
    line-height: var(--line-height-none);
    @include mq(sp) {
      top: 0;
      left: -44px;
      @include rect(36);
      font-size: sprem(18);
    }
  }
}
.lps_parts--flow .flow_item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 64px;
  left: 36px;
  display: block;
  width: 0;
  height: calc(100% - 80px);
  border-right: 1px solid var(--border-main);
  @include mq(sp) {
    top: 38px;
    left: 18px;
    height: calc(100% - 46px);
  }
}
.lps_parts--flow .txt-wh .ttl-03 {
  color: #fff;
}
.lps_parts--flow .flow_item.txt-wh:not(:last-child)::before {
  border-color: #fff;
}
.lps_parts--flow .txt-wh .ttl-03::after {
  color: var(--clr-main);
  background-color: #fff;
}
.lps_parts--flow .text img {
  max-width: 248px;
  margin-top: 0;
  margin-right: 40px;
  overflow: hidden;
  @include mq(sp) {
    float: none !important;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 16px !important;
  }
}
