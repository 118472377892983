@use "../abstracts/" as *;

/* home_purpose
********************************************** */
.home_purpose {
  padding-top: vw(75);
  @include mq(sp) {
    padding-top: spvw(24);
  }
}
.purpose_list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: rem(9);
  margin-inline: 5px;
  @include mq(sp) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.purpose_list li {
  position: relative;
  z-index: 0;
  aspect-ratio: 1;
  &::before,
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    transition: var(--transit-default);
  }
  &::before {
    z-index: -2;
    background-image: var(--bg);
    background-position: center;
    background-size: cover;
  }
  &::after {
    z-index: -1;
    background-color: rgba(0, 0, 0, 0);
  }
  &:hover::after {
    background-color: rgba(0, 0, 0, 0.8);
  }
}
.purpose_list a {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1em;
  color: var(--clr-wht);
  font-size: rem(28);
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.32em;
  @include mq(sp) {
    font-size: sprem(20);
  }
}
