@use "../abstracts" as *;

/* ##############################################################################

    SINGLE

############################################################################## */

/* --- アイキャッチ --- */
.eyecatch {
  margin-bottom: rem(32);
}

/* --- 目次 --- */
.ez-toc-debug-messages {
  display: none;
}
div#ez-toc-container {
  padding: rem(24);
  background-color: #fafafa;
}
.ez-toc-title-container {
  margin-bottom: rem(10);
}
div#ez-toc-container p.ez-toc-title {
  color: var(--clr-body, #333);
  font-weight: bold;
  font-size: rem(20);
}
#ez-toc-container.counter-hierarchy ul {
  font-size: rem(17);
}
#ez-toc-container.counter-hierarchy ul li {
  margin-top: rem(8);
}
#ez-toc-container.counter-hierarchy ul ul {
  margin-left: rem(16);
}
#ez-toc-container.counter-hierarchy ul ul a {
  position: relative;
  display: inline-block;
  padding-left: rem(16);
  &::before {
    content: "";
    position: absolute;
    top: rem(10);
    left: 0;
    width: rem(8);
    height: 0;
    border-bottom: 1px solid;
  }
}

/* pass
**************************************** */
#pass p {
  margin-bottom: 1em;
}
#pass input[name="post_password"] {
  padding: rem(5);
  border: solid 1px #aaa;
  outline: none;
}
#pass input[type="submit"] {
  padding: rem(4) rem(6);
  letter-spacing: 0.15em;
  background-color: #ddd;
  border-radius: rem(4);
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
}
#pass input[type="submit"]:hover {
  opacity: 0.7;
}

/* blog--share
**************************************** */
.blog--share {
  margin-top: vw(72);
  @include mq(sp) {
    margin-top: spvw(36);
  }
}
.blog--share_list {
  @include mq(sp, min) {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);
  }
  @include mq(sp) {
    display: flex;
    gap: 20px;
    @include center-flex;
  }
}
.blog--share_list a {
  display: flex;
  gap: 20px;
  @include center-flex;
  line-height: 1;
  font-weight: bold;
  @include mq(sp, min) {
    height: rem(40);
    border-inline: 1px solid var(--border-sub);
  }
}
.blog--share_list li {
  &.twitter a {
    color: #2ba1f2;
  }
  &.facebook a {
    color: #2477f2;
  }
  &.line a {
    color: #27c754;
  }
  &.pocket a {
    color: #ef4056;
  }
  &.linkedin a {
    color: #2867b2;
  }
  &.hatena a {
    color: #29a4de;
  }
}
.blog--share_list img {
  @include mq(sp) {
    width: 40px;
  }
}
.blog--share_list a:hover {
  opacity: 0.5;
}

/* blog--related
**************************************** */
.posts_scroll {
  overflow: auto;
  padding-bottom: rem(32);
}
.posts_scroll .post {
  display: block;
  width: 46%;
  flex-shrink: 0;
  @include mq(sp) {
    width: 70%;
  }
}
.posts_scroll .post:not(:last-child) {
  margin-right: 1rem;
}
.posts_scroll .post--img {
  margin-bottom: 1rem;
}
.posts_scroll .post--txt {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.posts_scroll::-webkit-scrollbar {
  height: rem(6);
}
.posts_scroll::-webkit-scrollbar-thumb {
  background-color: #999;
}
.posts_scroll::-webkit-scrollbar-track-piece {
  background-color: #eee;
}

/* wp-pagenavi
**************************************** */
.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
  @include mq(sp) {
    margin-top: 24px;
  }
}
.wp-pagenavi .pages {
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 1em;
}
.wp-pagenavi a {
  position: relative;
  padding: 0.5em 1em;
  background-color: var(--bg-main);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: 0;
}
.wp-pagenavi a:hover {
  background-color: var(--bg-main2);
}
.wp-pagenavi a {
  color: #fff;
}
.wp-pagenavi > *:not(.pages) {
  max-width: calc(100% / 2.6);
}
.archive .wp-pagenavi > *:not(.pages) {
  display: flex;
  justify-content: center;
  align-items: center;
  @include rect(40);
  @include mq(sp) {
    @include rect(32);
  }
}
.wp-pagenavi-single a[rel="prev"],
.wp-pagenavi-single a[rel="next"] {
  width: 40px;
}
.wp-pagenavi > *:not(.pages) {
  margin-bottom: 4px;
}
.wp-pagenavi > *:not(.pages):not(:last-child) {
  margin-right: 4px;
}
.wp-pagenavi .extend {
  width: 24px !important;
}
.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-top: 2px solid;
  border-right: 2px solid;
}
.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  width: 8px;
  height: 8px;
}
.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .first::after {
  transform: rotate(-135deg);
}
.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .last::before,
.wp-pagenavi .last::after {
  transform: rotate(45deg);
}
.wp-pagenavi-single a[rel="prev"]::before {
  transform: rotate(45deg);
}
.wp-pagenavi-single a[rel="next"]::before {
  transform: rotate(-135deg);
}
.wp-pagenavi .first::before,
.wp-pagenavi .last::before {
  left: -6px;
  @include mq(sp) {
    left: -5px;
  }
}
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  right: -6px;
  @include mq(sp) {
    right: -5px;
  }
}

@include mq(sp) {
  .wp-pagenavi-single a[rel="prev"],
  .wp-pagenavi-single a[rel="next"] {
    width: 32px;
  }
}

/* news
**************************************** */
.news--info .section_ttl {
  font-size: rem(36);
}

/* default
**************************************** */
.default--info {
  position: relative;
  overflow: hidden;
}
.default--info .deco01 {
  top: 0;
  left: vw(180);
  z-index: 2;
}
.default--info .sec_deco:first-child {
  padding-top: vw(100);
  .lps_sec-deco {
    top: vw(174);
  }
}
.single .event_dates {
  display: block;
  width: fit-content;
  margin-inline: auto;
  margin-bottom: rem(24);
  padding: 0.5em 2em;
  color: var(--clr-wht);
  font-size: rem(18);
  line-height: 1.2;
  background-color: var(--bg-main);
}
.yomigana {
  display: block;
  margin-top: 2em;
  line-height: var(--line-height-none);
  font-size: rem(15);
  letter-spacing: 0.28em;
}

// gallery_img
.gallery_img {
  @include mq(sp, min) {
    display: grid;
    gap: vw(60);
    grid-template-columns: perc(880, 1200, "%") perc(258, 1200, "%");
    // 940px 200px
  }
  @include mq(sp) {
    width: 90vw;
    margin-left: calc(50% - 45vw);
  }
}
.slide-default-gallery,
.slide-default-gallery .slick-slide {
  width: 100%;
  aspect-ratio: 3/2;
}
@include mq(sp, min) {
  .slide-default-gallery {
    margin-inline: 5px;
  }
}
.slide-default-gallery img {
  object-position: center;
  width: 100%;
  height: 100%;
}
.slide-default-gallery-thumb {
  @include mq(sp, min) {
    margin-top: -5px;
    margin-left: -6px;
  }
  .slick-arrow {
    inset-inline: 0;
    width: 100%;
    height: 23px;
    background: none;
    color: inherit;
    @include mq(sp) {
      top: 0;
      width: 10px;
      height: 100%;
      inset-inline: -10px;
    }
    &::before {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      margin-inline: auto;
      transform: rotate(40deg) skewX(-10deg);
      background: none;
      clip-path: none;
      @include mq(sp) {
        width: 10px;
        height: 10px;
      }
    }
  }
  .slick-prev {
    top: 0;
    &::before {
      border-top: 1px solid;
      border-left: 1px solid;
      @include mq(sp) {
        border-top: none;
        border-bottom: 1px solid;
      }
    }
  }
  .slick-next {
    top: auto;
    bottom: 0;
    @include mq(sp) {
      left: auto;
      transform: scale(-1, 1);
    }
    &::before {
      border-bottom: 1px solid;
      border-right: 1px solid;
      border-top: none;
      border-left: none;
      @include mq(sp, min) {
        margin-top: 20px;
      }
      @include mq(sp) {
        border-bottom: 1px solid;
        border-right: none;
        border-top: none;
        border-left: 1px solid;
      }
    }
  }
}
.slide-default-gallery-thumb .img {
  position: relative;
  width: 100%;
  aspect-ratio: 3/2;
  background-color: var(--bg-wht);
  cursor: pointer;
  @include mq(sp, min) {
    margin-block: 8px;
  }
  @include mq(sp) {
    margin-inline: 2px;
    width: 100px !important;
  }
  &::before {
    inset: -4px;
    @include mq(sp) {
      display: none;
    }
  }
  &:hover {
    img {
      opacity: 0.6;
    }
    &::before {
      border-color: #898989;
    }
  }
}
.slide-default-gallery-thumb .img img {
  width: 100%;
  height: 100%;
}
.slide-default-gallery-thumb .slick-list {
  width: 100%;
  height: auto !important;
  @include mq(sp, min) {
    position: absolute;
    inset-block: 16px;
    padding-inline: 5px;
  }
  @include mq(sp) {
    margin-top: 16px;
    margin-left: -2px;
  }
}
.slide-default-gallery-thumb img,
.slide-default-gallery-thumb img::before {
  transition: var(--transit-default);
}

// content_sec
.content_sec {
  font-size: rem(18);
}

// point_list
.point_list {
  display: grid;
  gap: 2rem;
  @include mq(sp, min) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.point_list li {
  position: relative;
  display: flex;
  gap: rem(32);
  padding: rem(20);
  border: 1px solid var(--border-main);
  @include lh(16, 28);
  letter-spacing: 0.12em;
  @include mq(sp) {
    gap: sprem(16);
    padding: sprem(10);
    flex-direction: column;
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - rem(10) / 2);
    width: rem(10);
    height: rem(10);
    background-color: #c0bda1;
    transform: rotate(45deg);
  }
  &::before {
    left: calc(rem(-10) / 2);
  }
  &::after {
    right: calc(rem(-10) / 2);
  }
}
.point_list img {
  width: 200px;
  @include mq(sp) {
    width: 100%;
  }
  flex-shrink: 0;
}
.point_list--ttl {
  margin-bottom: 0.5em;
  font-size: rem(24);
}
.point_list--txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  padding-block: vw(20);
}

// information_sec
.information_sec .box {
  padding: vw(72) vw(100);
  @include mq(sp) {
    padding: spvw(8);
  }
}
.information_sec .btn {
  margin-top: vw(32);
  @include mq(sp) {
    margin-top: spvw(16);
  }
}
.information_sec .btn a {
  max-width: 400px;
  min-width: min(400px, 100%);
  justify-content: center;
  padding: 0.5em 2em;
  background-color: #e0dcc9;
  border: none;
  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border: 1px solid;
    background: none;
  }
}
.information_sec .gmap {
  margin-top: vw(72);
  @include mq(sp) {
    margin-top: spvw(36);
  }
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    mix-blend-mode: hue;
    opacity: 0.8;
    background-color: var(--bg-main);
    pointer-events: none;
  }
}
.safari .information_sec .gmap::after {
  display: none;
}
.information_sec .gmap iframe {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  aspect-ratio: 1190/470;
  @include mq(sp) {
    aspect-ratio: 3/2;
  }
}

// movie_sec
.movie_sec {
  max-width: 1080px;
  margin-inline: auto;
  margin-top: vw(128);
}
.movie_list {
  @include mq(sp, min) {
    display: flex;
    flex-wrap: wrap;
    gap: rem(32);
    margin-inline: 5px;
  }
  @include mq(sp) {
    .slick-list {
      width: 100vw;
      margin-left: calc(50% - 50vw);
      padding-block: 4px !important;
    }
  }
}
.movie_list li {
  @include mq(sp, min) {
    width: calc(50% - 1rem);
    &:only-child {
      width: 100%;
    }
  }
  @include mq(sp) {
    width: 86vw;
    margin-inline: 8px;
  }
}
.movie {
  position: relative;
  display: block;
  overflow: hidden;
  background-color: var(--clr-body);
  aspect-ratio: 16/9;
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    margin: auto;
    width: rem(88);
    height: rem(88);
    background-image: url(../images/common/play.svg);
    background-size: cover;
    @include mq(sp) {
      width: sprem(60);
      height: sprem(60);
    }
  }
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: var(--transit-default);
  }
  &:hover img {
    transform: scale(1.05);
    opacity: 0.5;
  }
}

// related
.posts-related .ttl-01 {
  margin-bottom: vw(54);
  font-size: rem(28);
}

// slide-default
.slide-default {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  .post {
    width: max(vw(380), 300px);
    margin-inline: vw(32);
    @include mq(sp) {
    }
  }
  .slick-arrow {
    position: absolute;
    top: calc(max(vw(380), 300px) * 2 / 3 / 2 - max(vw(72), 40px) / 2);
  }
  .slick-prev {
    left: calc(50% - max(vw(680), 520px));
    @include mq(sp) {
      left: spvw(10);
    }
  }
  .slick-next {
    left: calc(50% + max(vw(610), 480px));
    @include mq(sp) {
      left: auto;
      right: spvw(10);
    }
  }
}
