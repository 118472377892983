@use "../abstracts/" as *;

/* home_recommend
********************************************** */
.home_recommend {
  padding-top: vw(140);
  padding-bottom: vw(200);
  @include mq(sp) {
    padding-bottom: spvw(100);
  }
  &::after {
    bottom: calc(((223 / 1920) * 100) * -1vw) !important;
    background-image: url(../images/section-parts-recommend.png) !important;
    aspect-ratio: 1920/465 !important;
    @include mq(sp) {
      bottom: calc(((170 / 2 / 414) * 100) * -1vw) !important;
      background-image: url(../images/section-parts-recommend-sp.png) !important;
      aspect-ratio: 828/339 !important;
    }
  }
  .deco03 {
    z-index: 2;
    top: 0;
    right: vw(165);
    @include mq(sp) {
      right: spvw(15);
    }
  }
}
.home_recommend .inner {
  z-index: 2;
  max-width: 1660px;
}
.home_recommend .ttl-01 {
  font-size: rem(36);
  @include lh(36, 61);
  letter-spacing: 0.2em;
  @include mq(sp, min) {
    display: flex;
    gap: 2rem;
    width: fit-content;
    margin-left: vw(92);
  }
  @include mq(sp) {
    margin-bottom: 1em;
    font-size: sprem(26);
    text-align: center;
  }
  &::before {
    margin-top: 0.4em;
    @include mq(sp) {
      margin-inline: auto;
    }
  }
}
.home_recommend--lists {
  position: relative;
  @include mq(sp, min) {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1660/973;
    &::before {
      content: "SPOT&EVENT";
      color: var(--clr-main);
      font-size: rem(11);
      font-family: var(--font-en);
      font-weight: bold;
      line-height: var(--line-height-none);
      opacity: 0.5;
    }
  }
}
.home_recommend--lists-img {
  position: absolute;
  z-index: -1;
  width: vw(334);
  aspect-ratio: 334/266;
  background-image: var(--deco);
  background-size: 100%;
}
.home_recommend--lists a {
  display: block;
}

.home_recommend--lists li {
  @include mq(sp, min) {
    position: absolute;
    width: fit-content;
  }
  @include mq(sp) {
    position: relative;
    &:not(:last-child) {
      margin-bottom: sprem(30);
    }
  }
}
.home_recommend--lists .recommend_text {
  position: absolute;
  display: flex;
  gap: 1.75rem;
  width: 100%;
  @include mq(sp) {
    bottom: -0.5em;
    left: -0.5em;
    align-items: center;
    gap: sprem(11);
    font-size: sprem(18);
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
  }
}
.home_recommend--lists .recommend_seasons {
  display: block;
  color: var(--bg-main);
  line-height: var(--line-height-none);
  flex-shrink: 0;
  @include mq(sp) {
    font-size: sprem(34);
  }
}
.home_recommend--lists .recommend_name {
  flex: 1;
  @include mq(sp) {
    @include lh(18, 30);
  }
}

// 細かいのは全部PC
@include mq(sp, min) {
  .home_recommend--lists li {
    // spring, autumn ============================
    &.spring,
    &.autumn {
      width: min(vw(680), 680px);
      font-size: rem(28);
      .recommend_text {
        flex-direction: column;
      }
      span {
        @include write-v;
      }
      .recommend_seasons {
        font-size: rem(56);
      }
    }

    // summer, winter ============================
    &.summer,
    &.winter {
      width: min(vw(400), 400px);
      font-size: rem(22);
      .recommend_text {
        align-items: center;
      }
      .recommend_seasons {
        font-size: rem(32);
      }
    }

    // spring ============================
    &.spring {
      top: vw(128);
      left: 0;
      .home_recommend--lists-img {
        bottom: vw(-100);
        left: vw(-130);
      }
      .recommend_text {
        top: vw(-32);
        right: vw(32);
      }
    }

    // summer ============================
    &.summer {
      top: 0;
      left: vw(820);
      .recommend_text {
        bottom: vw(-20);
        left: vw(-20);
      }
    }

    // autumn ============================
    &.autumn {
      bottom: vw(97);
      right: 0;
      .home_recommend--lists-img {
        top: vw(-100);
        right: vw(-130);
      }
      .recommend_text {
        top: vw(-32);
        left: 0;
        width: fit-content;
      }
    }

    // winter ============================
    &.winter {
      bottom: 0;
      left: vw(370);
      .recommend_text {
        top: vw(-20);
        right: vw(-20);
        flex-direction: row-reverse;
        text-align: right;
      }
      .txt-extended {
        transform-origin: top right;
      }
    }
  }
}
