@use "../abstracts/" as *;

/* home_sns_link
********************************************** */
.home_sns_link {
  position: relative;
  overflow: hidden;
  padding-top: vw(72);
  padding-bottom: calc(vw(64) + vw(325));
  @include mq(sp) {
    padding-top: spvw(32);
    padding-bottom: spvw(148);
  }
  .deco01 {
    top: vw(-10);
    left: vw(186);
    z-index: 2;
    @include mq(sp) {
      left: spvw(10);
    }
  }
}
.home_sns_link .ttl-01::before {
  margin-bottom: 16px;
}
.home_sns_link .ttl-01 span {
  &:first-child {
    font-family: Cormorant, serif;
    letter-spacing: 0.12em;
  }
  &:last-child {
    display: block;
    margin-top: 0.5em;
    font-size: rem(16);
    letter-spacing: 0.12em;
  }
}

/* home_sns
********************************************** */
.home_sns--ttl {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  -webkit-font-smoothing: antialiased;
  &::before,
  &::after {
    content: "";
    width: 6px;
    height: 6px;
    background-color: var(--bg-main);
    opacity: 0.5;
    transform: rotate(45deg);
  }
}
.home_sns--list {
  display: flex;
  flex-wrap: wrap;
  gap: vw(80) vw(56);
  @include mq(sp) {
    flex-direction: column;
    gap: spvw(40);
  }
  .sns-twitter,
  .sns-facebook {
    width: calc(50% - vw(56) / 2);
    @include mq(sp) {
      width: 100%;
    }
  }
  .sns-instagram {
    width: 100%;
  }
  .sns-youtube {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
}
.home_sns--list .fb_iframe_widget,
.home_sns--list .fb_iframe_widget span,
.home_sns--list iframe {
  width: 100% !important;
  @include mq(sp) {
    height: 200px !important;
  }
}
.home_sns--emb-inner {
  position: relative;
  overflow: hidden;
  background-color: var(--bg-wht);
}
.home_sns--emb-inner,
.home_sns--list iframe {
  height: 520px !important;
  @include mq(sp) {
    height: 200px !important;
  }
}
.home_sns--list .deco06 {
  right: vw(-120);
  bottom: vw(-50);
  @include mq(sp) {
    right: spvw(-60);
    bottom: spvw(-20);
  }
}
.home_sns--list .deco07 {
  left: vw(-120);
  bottom: vw(50);
  @include mq(sp) {
    left: spvw(-30);
    bottom: spvw(-30);
  }
}

// twitter
.twitter-timeline {
  overflow: auto;
}
.home_sns--emb-inner {
  &::before {
    content: "";
    position: absolute;
    inset: -1px;
    border-radius: 12px 12px 0 0;
    border: 3px solid var(--clr-wht);
    pointer-events: none;
    @include mq(sp) {
      display: none;
    }
  }
}

// instagram
.instagram .home_sns--emb {
  padding: vw(27);
}
#sbi_images {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: vw(32);
  @include mq(sp) {
    grid-template-columns: repeat(2, 1fr);
  }
}
#sb_instagram.sbi_col_4 #sbi_images .sbi_item {
  width: 100%;
  background-color: var(--clr-body);
  &:hover .sbi_photo {
    opacity: 0.5 !important;
  }
}
#sb_instagram .sbi_photo_wrap,
.sbi_photo {
  height: 100%;
}

// youtube
.slide-movie .slick-slide {
  width: min(86vw, 640px);
  margin-inline: rem(16);
  @include mq(sp) {
    margin-inline: sprem(8);
  }
}
.slide-movie .slick-prev {
  left: spvw(8);
}
.slide-movie .slick-next {
  right: spvw(8);
}

/* home_links
********************************************** */
.home_links {
  margin-top: vw(124);
  @include mq(sp) {
    margin-top: spvw(80);
  }
}

// home_links--guide
.home_links--guide {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.28rem;
  margin-bottom: vw(92);
  @include mq(sp) {
    grid-template-columns: 1fr;
    margin-bottom: spvw(38);
  }
}
.home_links--guide li {
  position: relative;
  z-index: 0;
  aspect-ratio: 597/280;
  font-size: rem(19);
  background-image: var(--bg);
  background-position: center;
  background-size: cover;
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    transition: var(--transit-default);
    background-color: rgba(0, 0, 0, 0);
  }
  &:hover::after {
    background-color: rgba(0, 0, 0, 0.7);
  }
}
.home_links--guide a {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
  color: var(--clr-wht);
  line-height: 1.5;
  @include mq(sp) {
    padding: 3em 2em 2em;
  }
  &::before {
    position: absolute;
    top: rem(32);
  }
  &::after {
    content: "";
    position: absolute;
    inset: 4px;
    border: 1px solid color("main", 500);
    pointer-events: none;
  }
}

// home_links--lists
.home_links--lists-wrap {
  position: relative;
  padding-block: rem(29);
  &:not(:last-child)::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 6px;
    border-block: 1px solid #c7c39e;
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
}
.home_links--lists {
  display: flex;
  gap: vw(0) vw(21);
  flex-wrap: wrap;
  @include mq(sp) {
    flex-direction: column;
    gap: 4px;
  }
  @include mq(sp, min) {
    .catalog {
      width: perc(320, 1200, "%");
    }
  }
}
.home_links--bnr .bnr {
  flex-shrink: 0;
  transition: var(--transit-default);
  background-color: var(--clr-body);
  img {
    width: 100%;
    height: -webkit-fill-available;
  }
  a:hover {
    opacity: 0.5;
  }
  @include mq(sp, min) {
    &.catalog {
      width: 26.666%;
    }
  }
}
.home_links--bnr {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: vw(10);
  @include mq(sp) {
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
  }
  @include mq(sp, min) {
    &.col2 {
      display: flex;
      justify-content: center;
    }
  }
}
.home_links--bnr li {
  border: 1px solid #eee;
}
.home_links--bnr img {
  width: 100%;
}
