@use "../abstracts" as *;

/* ##############################################################################

    FOOTER

############################################################################## */

body:not(.page-contact:not(.page-thanks)) .footer {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    inset-inline: 0;
    z-index: 1;
    width: 100vw;
    background-size: 100%;
    top: calc(((315 / 1920) * 100) * -1vw);
    aspect-ratio: 1920/316;
    background-image: url(../images/common/section-parts03.png);
    @include mq(sp) {
      top: calc(((234 / 2 / 414) * 100) * -1vw);
      aspect-ratio: 828/234;
      background-image: url(../images/common/section-parts03-sp.png);
    }
  }
}
.footer--logo {
  margin-bottom: 3rem;
}
.footer--logo img {
  width: rem(158);
}
.footer--logo svg {
  display: block;
  width: rem(218);
  margin-top: 1.5rem;
  fill: var(--bg-wht) !important;
}
.footer--logo a {
  display: inline-block;
}
.footer--logo a:hover {
  opacity: 0.7;
}

// footer--address
.footer--address {
  max-width: 800px;
  margin-inline: auto;
  margin-bottom: 4rem;
  padding: 1.5rem;
  line-height: 1.8;
  border: 1px solid currentColor;
  @include mq(sp, min) {
    width: 90%;
  }
}
.footer--address-ttl {
  margin-bottom: 1em;
  font-size: rem(18);
  line-height: 1;
  &::before {
    content: "";
    width: 5px;
    height: 5px;
    display: block;
    margin-inline: auto;
    margin-bottom: 1rem;
    background-color: currentColor;
    transform: rotate(45deg);
  }
}
.footer--address-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  &::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-right: 1px solid;
    border-top: 1px solid;
    transform: rotate(45deg);
  }
}
@include mq(sp) {
  .footer--address p {
    gap: 0 !important;
  }
}

/* footer_top
********************************************** */
// fnav
.fnav--menu {
  margin-bottom: 1rem;
  font-size: rem(18);
  @include mq(sp, min) {
    margin-right: calc(-1.5em - 0.06em);
  }
  @include mq(sp) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 1rem;
    padding-block: 1rem;
    font-size: sprem(16);
    border-block: 1px solid rgba(255, 255, 255, 0.5);
  }
}
.fnav--menu li {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 6px / 2);
    width: 5px;
    height: 5px;
    display: block;
    background-color: currentColor;
    transform: rotate(45deg);
    @include mq(med, min) {
      right: 0;
    }
    @include mq(med) {
      left: 0;
    }
  }
  @include mq(med, min) {
    &:last-child::after {
      display: none;
    }
  }
}
@include mq(sp, min) {
  .fnav .sub-menu {
    justify-content: center;
  }
}
.fnav--menu a {
  display: block;
  padding: 0 1em;
  @include mq(sp) {
    position: relative;
    padding: 0.5em 1em;
    line-height: 1.5;
  }
  &:hover {
    opacity: 0.7;
  }
}

/* ---sns_area --- */
.sns--list {
  gap: 16px;
}
.sns--list a {
  display: grid;
  place-items: center;
  &:hover svg {
    fill: var(--bg-main);
  }
  @include mq(sp) {
    color: var(--clr-wht);
  }
}
.sns--list svg {
  width: 32px;
  height: 32px;
  fill: currentColor;
}

/* footer_btm
*************************************************** */
.footer_btm {
  padding-top: vw(48);
  padding-bottom: vw(64);
  @include mq(sp) {
    padding-top: spvw(48);
  }
}
.copyright small {
  font-size: rem(12);
  @include mq(sp) {
    font-size: sprem(12);
  }
}
