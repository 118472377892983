@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: vw(120);
  width: 90%;
  max-width: 1600px;
  margin-inline: auto;
  letter-spacing: 0.2em;
  aspect-ratio: 1600/440;
  @include mq(sp) {
    margin-top: spvw(12);
  }
  &::before,
  &::after {
    content: "";
    width: 1px;
    height: 80px;
    margin-inline: auto;
    background-color: var(--border-main);
    @include mq(sp, min) {
      position: absolute;
      inset-inline: 0;
    }
  }
  &::before {
    top: -40px;
    @include mq(sp) {
      display: none;
    }
  }
  &::after {
    @include mq(sp, min) {
      bottom: -40px;
    }
    @include mq(sp) {
      height: 57px;
      margin-top: 16px;
    }
  }
  .deco01 {
    top: vw(-143);
    left: vw(16);
    z-index: 2;
  }
}
.page_ttl--bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: var(--bg-wht);
  background-image: var(--bg);
  background-size: cover;
  background-position: center;
  mask-image: url(../images/common/page_ttl-shape.png);
  mask-size: contain;
  @include mq(sp) {
    mask-image: url(../images/common/page_ttl-shape-sp.png);
    aspect-ratio: 760/320;
  }
}
.page_ttl--ttl-wrap {
  width: 100%;
  display: grid;
  place-items: center;
  @include mq(sp) {
    aspect-ratio: 760/320;
  }
}
.page_ttl-jp {
  color: var(--clr-wht);
  font-size: rem(46);
  font-family: var(--font-jp);
  -webkit-font-smoothing: antialiased;
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.page_ttl-en {
  display: block;
  color: var(--clr-wht);
  font-size: rem(12);
  font-family: var(--font-en);
  text-transform: uppercase;
  letter-spacing: --ltr-space-default;
  line-height: var(--line-height-none);
  @include mq(sp) {
    font-size: sprem(12);
  }
}
.page_ttl-notice {
  width: 100%;
  margin-top: 2em;
  @include mq(sp, min) {
    color: var(--clr-wht);
  }
  @include mq(sp) {
    font-size: sprem(13);
    @include lh(13, 22);
  }
}
.page_ttl--list_wrap {
  margin-bottom: vw(120);
}
.page_ttl--list {
  position: relative;
  z-index: 1;
  counter-reset: number;
  @include mq(sp) {
    gap: 1rem 0;
  }
}
.page_ttl--list li {
  border-right: 1px solid var(--border-main);
  &:first-child {
    border-left: 1px solid var(--border-main);
  }
  @include mq(sp, min, ps) {
    min-height: 96px;
    max-width: 400px;
  }
}
@include mq(sp) {
  .page_ttl--list.flex-sp-col2 li:nth-child(2n) + li {
    border-left: 1px solid var(--border-main);
  }
}
.page_ttl--list a {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1em;
  height: 100%;
  padding: 0.5em 2.5em;
  line-height: var(--line-height-hdr, 1.4);
  @include mq(sp) {
    gap: 0.5rem;
    padding: 0.5em;
    font-size: sprem(12);
    letter-spacing: 0.05em;
  }
  &::before,
  &::after {
    display: block;
    margin-inline: auto;
  }
  &::before {
    counter-increment: number;
    content: "0" counter(number);
    color: var(--clr-main);
    font-size: rem(12);
    font-family: var(--font-en);
    font-weight: bold;
    line-height: var(--line-height-none);
    @include mq(sp) {
      display: none;
    }
  }
  &::after {
    content: "";
    @include rect(15, 8);
    margin-top: auto;
    background-color: var(--bg-blk);
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    transition: var(--transit-default, all 0.4s ease-out);
    @include mq(sp) {
      @include rect(9, 4);
    }
  }
  &:hover {
    color: var(--clr-main);
    &::after {
      transform: translateY(4px);
    }
  }
}

/* --- ttl --- */
.txt-extended {
  display: inline-block;
  width: 90.9%;
  transform: scale(1.1, 1);
  transform-origin: top left;
}
.ttl-01 .txt-extended,
.txt-ctr .txt-extended {
  transform-origin: center;
}
.ttl-01,
.ttl-02,
.ttl-03 {
  letter-spacing: 0.1em;
}
.ttl-01 {
  font-size: rem(40);
  @include lh(40, 64);
  letter-spacing: 0.2em;
  @include mq(sp) {
    font-size: sprem(26);
    @include lh(30, 45);
  }
}
.ttl-01-sm {
  font-size: rem(36);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.ttl-02 {
  color: var(--clr-main);
  font-size: rem(36);
  @include lh(36, 62);
  letter-spacing: 0.2em;
  @include mq(sp) {
    font-size: sprem(24);
    @include lh(24, 36);
  }
}
.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: rem(38);
  @include lh(38, 58);
  letter-spacing: 0.2em;
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.ttl-03 {
  color: var(--clr-main);
  font-size: rem(30);
  @include lh(30, 50);
  letter-spacing: 0.2em;
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.lps_sec .ttl-01,
.lps_sec .ttl-02,
.lps_sec .ttl-03 {
  position: relative;
  z-index: 1;
}
.lps_sec .ttl-01,
.lps_sec .ttl-02,
.lps_sec .ttl-03:not(:last-child) {
  margin-bottom: 1em;
}
.ttl-04 {
  font-size: rem(24);
}
.lps_sec .column-3 .ttl-03,
.lps_sec .column-4 .ttl-03 {
  font-size: rem(26);
  @include lh(26, 50);
  letter-spacing: 0.2em;
  @include mq(sp) {
    font-size: sprem(24);
  }
}
