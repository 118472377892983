@use "../abstracts" as *;

/* --- section_pdg --- */
.section_pdg {
  padding-top: vw(200);
  padding-bottom: vw(325);
  @include mq(sp) {
    padding-top: spvw(72);
    padding-bottom: spvw(124);
  }
}
body.page-contact:not(.page-thanks) .section_pdg {
  padding-bottom: vw(100);
  @include mq(sp) {
    padding-bottom: spvw(50);
  }
}
.section_pdg-top {
  padding-top: vw(200);
  @include mq(sp) {
    padding-top: spvw(200);
  }
}
.section_pdg-btm {
  padding-bottom: vw(325);
  @include mq(sp) {
    padding-bottom: spvw(160);
  }
}
.section_pdg-sm {
  padding-block: vw(100);
  @include mq(sp) {
    padding-top: spvw(34);
    padding-bottom: spvw(34);
  }
}
