@use "../abstracts/" as *;

/* home_intro
********************************************** */
.home_intro {
  position: relative;
  padding-bottom: vw(130);
  @include mq(sp) {
    padding-bottom: spvw(47);
  }
}
.home_intro--ttl {
  position: relative;
  z-index: 2;
  white-space: nowrap;
  @include mq(sp, min) {
    position: absolute;
    top: vw(236);
    inset-inline: 0;
    z-index: 1;
    width: 90%;
    max-width: 1360px;
    margin-inline: auto;
  }
  &::before {
    content: "";
    position: absolute;
    top: rem(-30);
    right: rem(52);
    width: 6px;
    height: 6px;
    transform: rotate(45deg);
    background-color: var(--clr-body);
    @include mq(sp) {
      right: sprem(33);
    }
  }
}
.ipad.safari .home_intro--ttl::before {
  right: rem(42);
}
.home_intro--ttl span {
  font-size: rem(50);
  @include lh(50, 101);
  letter-spacing: 0.3em;
  @include write-v;
  @include mq(sp) {
    font-size: sprem(36);
    @include lh(36, 67);
  }
}

// home_intro--top
.home_intro--top {
  z-index: 0;
  padding-top: vw(450);
  padding-bottom: vw(130);
}
.home_intro--cont {
  position: relative;
  z-index: 1;
}
.home_intro--cont-img {
  position: absolute;
  z-index: -1;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
}
.home_intro--cont-img01 {
  top: vw(-260);
  left: vw(-200);
  width: vw(436);
  background-image: url(../images/about-img01.png);
  aspect-ratio: 436/322;
  @include mq(sp) {
    top: spvw(-300);
    width: spvw(253);
  }
}
.home_intro--cont-img02 {
  bottom: vw(-110);
  right: vw(-246);
  width: vw(252);
  background-image: url(../images/about-img02.png);
  aspect-ratio: 252/318;
  @include mq(sp) {
    top: spvw(-10);
    bottom: auto;
    width: spvw(169);
  }
}
.home_intro--cont .txtarea {
  width: 50%;
  margin-bottom: vw(64);
  font-size: rem(17);
  text-align: justify;
  @include lh(17, 40);
  @include mq(sp) {
    width: 93%;
    font-size: sprem(14);
    @include lh(14, 26);
  }
}
.home_intro--btn {
  width: fit-content;
  @include mq(pc) {
    margin-top: 20%;
  }
  @include mq(med) {
    width: 90%;
    margin-inline: auto;
    margin-top: calc(367 / (414 * 0.86) * 100%);
  }
}
.home_intro--btn .btn:not(:last-child) {
  margin-bottom: 1rem;
}
.home_intro--btn .btn a {
  width: 100%;
  min-width: auto;
}
.home_intro--cont .txtarea p:not(:last-child) {
  margin-bottom: calc(1em + 50em / 101);
}
.home_intro--map {
  position: absolute;
  background-image: url(../images/about-map.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  @include mq(med, min) {
    bottom: max(perc(-170, 1920, "vw"), -100px);
    right: calc(50% - min(perc(950, 1600, "vw"), 950px));
    width: min(perc(1763, 1600, "vw"), 1763px);
    aspect-ratio: 1763/1378;
  }
  @include mq(med) {
    inset: 0;
    top: 52%;
  }
  @include mq(sp) {
    background-image: url(../images/about-map-sp.png);
  }
}
.home_intro--map li {
  position: absolute;
  color: var(--clr-wht);
  font-size: rem(14);
  font-weight: bold;
  line-height: var(--line-height-none);
  white-space: nowrap;
  text-shadow: 0 0 21px rgba(5, 15, 25, 0.48);
  span {
    width: 110%;
    margin-left: -10%;
    text-align: center;
  }
  &:not(.city-mino)::before {
    content: "";
    display: block;
    width: 9px;
    height: 9px;
    margin-inline: auto;
    margin-bottom: 14px;
    background-color: var(--bg-wht);
    border-radius: 50%;
    box-shadow: 0 0 21px rgba(5, 15, 25, 0.48);
    @include mq(sp) {
      width: 5px;
      height: 5px;
      margin-bottom: 8px;
    }
  }
}
.home_intro--map li {
  // city-mino ========================
  &.city-mino {
    top: perc(462, 1378, "%");
    left: perc(875, 1763, "%");
    width: min(perc(197, 1600, "vw"), 197px);
    height: min(perc(340, 1600, "vw"), 340px);
    color: var(--clr-body);
    text-shadow: none;
    @include mq(sp) {
      top: spvw(337);
      left: spvw(38);
      width: spvw(129);
      height: spvw(152);
    }
    .city-mino-txt {
      position: relative;
      width: fit-content;
      &::before,
      &::after {
        content: "";
        display: block;
        margin-inline: auto;
      }
      &::before {
        width: min(perc(58, 1600, "vw"), 58px);
        height: min(perc(59, 1600, "vw"), 59px);
        margin-bottom: 1rem;
        background-image: url(../images/about-mino-logo.png);
        background-size: contain;
        aspect-ratio: 58/59;
        @include mq(sp) {
          width: spvw(35);
          height: spvw(35);
        }
      }
      &::after {
        position: absolute;
        bottom: 0;
        inset-inline: 0;
        z-index: -1;
        width: 58px;
        height: 58px;
        border-radius: 50%;
        background-color: rgb(255 255 255 / 50%);
        filter: blur(10px);
      }
      span {
        display: block;
        font-size: min(perc(16, 1600, "vw"), rem(16));
        @include mq(sp) {
          font-size: min(perc(12, 414, "vw"), sprem(12));
          font-weight: normal;
        }
      }
      .font-jp {
        margin-bottom: 0.5em;
        font-size: min(perc(25, 1600, "vw"), rem(25));
        @include mq(sp) {
          font-size: min(perc(15, 414, "vw"), sprem(15));
        }
      }
    }
    svg {
      position: absolute;
      right: 0;
      bottom: 0;
      width: min(perc(157, 1600, "vw"), 157px);
      height: min(perc(190, 1600, "vw"), 190px);
      @include mq(sp) {
        width: spvw(107);
        height: spvw(65);
      }
      path {
        transform-origin: 140.5px 173.5px;
        @include mq(sp) {
          transform-origin: 97px 55px;
        }
      }
      .dot2 {
        animation: dots_light 3s ease-out infinite forwards;
      }
      .dot3 {
        animation: dots_light 3s ease-out -1.5s infinite forwards;
      }
    }
  }

  // tokyo ========================
  &.tokyo {
    top: perc(760, 1378, "%");
    left: perc(1394, 1763, "%");
    @include mq(sp) {
      top: spvw(462);
      left: spvw(355);
    }
  }

  // osaka ========================
  &.osaka {
    top: perc(968, 1378, "%");
    left: perc(820, 1763, "%");
    @include mq(sp) {
      top: spvw(580);
      left: spvw(10);
    }
  }

  // nagoya ========================
  &.nagoya {
    top: perc(890, 1378, "%");
    left: perc(1050, 1763, "%");
    @include mq(sp) {
      top: spvw(540);
      left: spvw(150);
    }
  }

  // kyoto ========================
  &.kyoto {
    top: perc(845, 1378, "%");
    left: perc(834, 1763, "%");
    @include mq(sp) {
      top: spvw(510);
      left: spvw(20);
    }
  }
}

// home_intro--fixed
.home_intro--fixed {
  position: fixed;
  right: vw(88);
  bottom: vw(88);
  z-index: z(top);
  width: 220px;
  transition: var(--transit-default);
  transform: translateX(calc(100% + vw(88)));
  @include mq(sp) {
    right: 0;
    bottom: 0;
    width: 100%;
    transform: translateY(100%);
  }
  &.is-show {
    transform: translate(0);
  }
}
.home_intro--fixed a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 24px;
  padding-bottom: 40px;
  @include mq(sp, min) {
    flex-direction: column;
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    border-radius: 50%;
    aspect-ratio: 1;
  }
  @include mq(sp) {
    justify-content: space-between;
    gap: sprem(30);
    padding: sprem(12) sprem(26);
  }
  &::before {
    content: "";
    inset: 5px !important;
    @include mq(sp, min) {
      border-radius: 50%;
    }
    @include mq(sp) {
      inset: 3px !important;
    }
  }
}
.home_intro--fixed-en {
  @include mq(sp, min) {
    display: block;
    margin-bottom: 1em;
    color: var(--bg-main);
    font-size: rem(11);
    @include lh(11, 16);
    text-align: center;
    letter-spacing: 0.12em;
    transform-origin: top center;
  }
  @include mq(sp) {
    display: none;
  }
}
.home_intro--fixed-ttl {
  font-size: rem(24);
  font-family: Cormorant, serif;
  @include lh(24, 32);
  letter-spacing: 0;
  @include mq(sp, min) {
    margin-bottom: 0.5em;
    text-align: center;
    transform-origin: top center;
  }
  @include mq(sp) {
    width: fit-content;
    line-height: 1.2;
  }
}
.home_intro--fixed-txt {
  display: flex;
  align-items: center;
  color: #959595;
  font-size: rem(13);
  @include lh(13, 17);
  letter-spacing: 0.12em;
  @include mq(sp, min) {
    padding-inline: 1em;
  }
  svg {
    flex-shrink: 0;
    width: 19px;
    height: 21px;
    fill: var(--bg-main);
  }
}

.home_intro--movie {
  z-index: 3;
  iframe {
    z-index: 1;
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
  }
}
