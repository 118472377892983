@use "../abstracts" as *;

/* bg-movie
********************************************** */
.bg-movie {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: -1;
  width: 100%;
  height: 100%;
  translate: -50% -50%;
  video {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    display: block;
    width: auto;
    height: auto;
    min-height: 100%;
    min-width: 100%;
  }
}

/* hero
********************************************** */
.hero {
  position: relative;
  overflow: hidden;
  z-index: 0;
  height: max(vw(968), 850px);
  @include mq(sp, min) {
    padding-top: rem(44);
  }
  @include mq(sp) {
    height: spvw(620);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 100vw;
    height: 20px;
    background-image: url(../images/common/img_gallery--deco.png);
    background-position: bottom;
    background-size: auto 100%;
    @include mq(sp) {
      height: 10px;
    }
  }
  &::before {
    transform: scale(1, -1);
    top: 0;
    @include mq(sp, min) {
      top: rem(44);
    }
  }
  &::after {
    bottom: 0;
    background-position: top;
  }
}
.hero--catch {
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: rem(52);
  @include lh(52, 102);
  letter-spacing: 0.28em;
  @include mq(sp) {
    font-size: sprem(34);
    @include lh(34, 60);
  }
}
.hero_slides {
  position: absolute;
  inset: 0;
  @include mq(sp, min) {
    top: rem(44);
  }
}
.hero_slide {
  overflow: hidden;
}
.hero_slide-sp {
  display: none !important;
}
.hero_slides,
.hero_slide,
.hero_slide .slick-list,
.hero_slide .slick-track,
.hero_slide .slick-slider,
.hero_slide .slick-slide {
  width: 100%;
  height: 100% !important;
}
.hero_slide .slide-animation .img {
  animation: hero_slide 6s ease-out forwards;
}
.hero_slide .img {
  position: absolute;
  inset: 0;
  @include mq(sp) {
    .rellax {
      position: absolute;
      inset: 0;
      margin-block: -5%;
    }
  }
}

// slick-dots
.hero_slide .slick-dots {
  position: absolute;
  overflow: hidden;
  color: var(--clr-wht);
  font-size: rem(14);
  font-family: var(--font-primary);
  line-height: var(--line-height-none);
  letter-spacing: 0.28em;
  @include mq(sp, min) {
    right: vw(66);
    bottom: vw(117);
    padding-right: 16px;
  }
  @include mq(sp) {
    display: none;
  }
}
.hero_slide .slick-dots li {
  position: relative;
  padding: vw(20) vw(28);
  text-align: right;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    position: absolute;
    inset-block: 0;
    right: 0;
    width: 1px;
    background-color: var(--bg-wht);
    opacity: 0.4;
  }
  &::before {
    z-index: 1;
    translate: 0 -2em;
    transform-origin: top;
  }
}
.hero_slide .slick-dots li span {
  opacity: 0.5;
  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: calc(50% - 6px / 2);
    right: calc(-6px / 2);
    width: 6px;
    height: 6px;
    color: var(--clr-wht);
    background-color: var(--bg-wht);
    rotate: 45deg;
  }
}
.hero_slide .slick-dots li.slick-active {
  color: var(--clr-wht);
  opacity: 1;
  &::before {
    opacity: 1;
    // animation: hero_dots_line 6s ease-out forwards;
  }
  span {
    opacity: 1;
  }
  span::before {
    background-color: var(--bg-wht);
  }
  span::after {
    background-color: var(--bg-wht);
    animation: hero_dots_dot 3s ease-out -1s infinite forwards;
  }
}

@include mq(sp) {
  .hero_slide-pc {
    display: none !important;
  }
  .hero_slide-sp {
    display: block !important;
  }
}
.hero_slide-sp-dots {
  @include mq(sp, min) {
    display: none !important;
  }
  position: absolute !important;
  bottom: spvw(37);
  left: 0;
  right: 0;
  color: var(--clr-wht);
  .slick-slide {
    margin-inline: spvw(17);
    font-size: sprem(13);
    letter-spacing: 0.2em;
    line-height: var(--line-height-none);
    &:not(.slick-current) {
      opacity: 0.5;
    }
    &::after {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      margin-inline: auto;
      margin-top: sprem(15);
      border-radius: 50%;
      border: 1px solid var(--bg-wht);
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
  @include mq(sp) {
  }
}

/* hero_news
********************************************** */
.hero_news {
  @include mq(sp, min) {
    position: absolute;
    left: vw(43);
    top: max(vw(870), 770px);
    display: flex;
    width: vw(784);
  }
  @include mq(sp) {
    position: relative;
    padding: 7vw;
    background-color: var(--bg-body);
    background-image: url(../images/common/ptn01.jpg);
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: var(--bg-wht);
    background-image: url(../images/common/ptn03.jpg);
    opacity: 0.7;
    @include mq(sp) {
      inset: 7vw;
    }
  }
}
.hero_news--ttl {
  position: relative;
  z-index: 0;
  display: grid;
  place-content: center;
  flex-shrink: 0;
  padding: 0.5em;
  color: var(--clr-wht);
  font-size: rem(14);
  letter-spacing: 0.08em;
  line-height: var(--line-height-none);
  background-color: var(--bg-main);
  @include mq(sp, min) {
    width: rem(136);
  }
}
.hero_news--list {
  padding: 0.8em 2em;
  @include mq(sp, min) {
    width: calc(100% - rem(136));
  }
  @include mq(sp) {
    padding: 0.8em 1em;
  }
}
.hero_news--list .post {
  display: flex !important;
  align-items: center;
  gap: 1em;
}
.hero_news--list .post--date {
  flex-shrink: 0;
  letter-spacing: 0.06em;
}
.hero_news--list .post--link {
  display: flex !important;
}
.hero_news--list .post--ttl {
  flex: 1;
  font-size: rem(15);
  letter-spacing: 0.08em;
  width: 70%;
}
.hero_news--list .post--ttl span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
