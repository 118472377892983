@use "../abstracts/" as *;

/* home_event
********************************************** */
.home_event {
  position: relative;
  z-index: 1;
  padding-top: vw(48);
  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
  }
  &::before {
    top: vw(120);
    left: 0;
    width: vw(1119);
    background-image: url(../images/event-bg.png);
    background-size: contain;
    aspect-ratio: 1119/679;
    @include mq(sp) {
      top: auto;
      bottom: spvw(-70);
      width: 100%;
      background-image: url(../images/event-bg-sp.png);
      aspect-ratio: 828/408;
    }
  }
  &::after {
    content: "";
    top: vw(48);
    bottom: 0;
    left: vw(180);
    right: 0;
    background-color: var(--bg-wht);
    background-image: url(../images/common/ptn03.jpg);
    @include mq(sp) {
      left: spvw(20);
    }
  }
}
.home_event--inner {
  position: relative;
  padding-block: vw(72);
  @include mq(sp, min) {
    margin-left: vw(180);
  }
  .deco01 {
    z-index: 2;
    top: vw(-60);
    left: vw(26);
  }
  .deco02 {
    z-index: 2;
    bottom: vw(-120);
    right: vw(75);
  }
}
.home_event--ttl {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: vw(80);
  height: 100%;
  @include mq(sp, min) {
    position: absolute;
    top: 0;
    left: 0;
    width: vw(408);
  }
  @include mq(sp) {
    margin-bottom: 2em;
  }
}
.home_event--ttl .ttl-01 {
  width: 100%;
  font-size: rem(32);
}
.home_event--btn {
  @include mq(sp) {
    text-align: center;
    margin-block: 2rem 1rem;
  }
}
.home_event--btn a {
  position: relative;
  padding-right: 72px;
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 1px / 2);
    right: calc(41px / 2);
    display: block;
    width: 40px;
    border-bottom: 1px solid var(--bg-main);
  }
  &:hover span::before {
    animation: dots_light 3s ease-out infinite forwards;
  }
}
.home_event--btn span {
  display: grid;
  place-items: center;
  position: absolute;
  top: calc(50% - 41px / 2);
  right: 0;
  width: 41px;
  height: 41px;
  background-color: #f0ede0;
  border: 1px solid var(--clr-main);
  border-radius: 50%;
  &::before {
    content: "";
    width: 6px;
    height: 6px;
    background-color: var(--bg-main);
    rotate: 45deg;
  }
}

.event_list {
  @include mq(sp, min) {
    margin-left: vw(408);
  }
}
.event_list .post {
  width: vw(373);
  margin-inline: vw(20);
  @include mq(sp) {
    width: spvw(320);
    margin-inline: spvw(10);
  }
  &.hot::before {
    content: "HOT!";
    position: absolute;
    right: rem(-10);
    top: rem(-18);
    z-index: 1;
    display: grid;
    place-items: center;
    width: rem(58);
    aspect-ratio: 1;
    color: var(--clr-wht);
    font-size: rem(12);
    font-family: var(--font-en);
    font-weight: bold;
    line-height: var(--line-height-none);
    letter-spacing: 0.08em;
    border-radius: 50%;
    background-color: var(--bg-main);
  }
}
.event_list .post--img {
  margin-bottom: 1rem;
  border: 1px solid var(--border-main);
}
.event_list .post--ttl {
  margin-bottom: 0.5rem;
  font-size: rem(20);
}
.event_list .event_venue {
  position: relative;
  padding-left: 24px;
  line-height: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.event_list .event_venue svg {
  position: absolute;
  left: 0;
  width: 15px;
  height: 19px;
  fill: var(--bg-main);
}
.event_list .event_venue span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.slide-event .slick-list {
  margin-top: rem(-18);
  padding-top: rem(18) !important;
}
.slide-event .slick-arrow {
  top: calc(spvw(320) * 2 / 3 / 2 - max(vw(72), 40px) / 2 + rem(18));
}
.slide-event .slick-prev {
  left: spvw(18);
}
.slide-event .slick-next {
  right: spvw(18);
}
