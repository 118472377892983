@use "../abstracts" as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: relative;
  z-index: z(header);
  width: 100%;
  padding-top: rem(5);
  @include mq(sp) {
    position: fixed;
    top: 0;
    left: 0;
    height: 64px;
    background-color: var(--bg-body);
    background-image: url(../images/common/ptn01.jpg);
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: z(header);
    height: rem(5);
    background-color: var(--bg-blk);
  }
  @include mq(sp) {
    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      z-index: 1;
      width: 100vw;
      height: 10px;
      background-image: url(/wp/wp-content/themes/standard_sass/images/common/img_gallery--deco.png);
      background-position: bottom;
      background-size: auto 100%;
      transform: scale(1, -1);
    }
  }
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: vw(48);
    padding-right: vw(64);
    @include mq(sp) {
      padding-left: spvw(10);
      padding-right: 0;
    }
  }
}
@include mq(sp, min) {
  .home {
    .header {
      position: absolute;
      top: 0;
    }
  }
}
.header--logo {
  position: relative;
  z-index: z(header);
  display: flex;
  align-items: center;
}
.page-contact .header--logo {
  padding-top: vw(20);
}
.header--logo .logo-mark {
  width: clamp(54px, perc(106, 1600, "vw"), 106px);
  transition: var(--transit-default);
}
.header--logo .logo-mark-wh {
  position: absolute;
  opacity: 0;
}
.header--logo .logo-type {
  width: clamp(162px, perc(217, 1600, "vw"), 217px);
  transition: var(--transit-default);
}
.header--logo a {
  display: flex;
  align-items: center;
}
.header--logo a:hover {
  opacity: 0.7;
}

@include mq(sp) {
  .header.active {
    .header--logo .logo-mark {
      opacity: 0;
    }
    .header--logo .logo-mark-wh {
      opacity: 1;
    }
    .header--logo .logo-type {
      fill: var(--clr-wht) !important;
    }
  }
}
@include mq(med, min, ps) {
  .fix-gnav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-left: vw(48);
    padding-right: vw(64);
    z-index: z(header);
    height: 100px;
    padding-top: 5px;
    transition: var(--transit-default);
    transform: translateY(-100%);
    opacity: 0;
    box-shadow: 0 0 21px rgb(140 140 140 / 20%);
    @include mq(sp, min) {
      display: flex;
      justify-content: space-between;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 5px;
      background-color: var(--bg-blk);
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -20px;
      left: 0;
      z-index: 1;
      width: 100vw;
      height: 20px;
      background-image: url(/wp/wp-content/themes/standard_sass/images/common/img_gallery--deco.png);
      background-position: bottom;
      background-size: auto 100%;
      transform: scale(1, -1);
      @include mq(sp) {
        bottom: -10px;
        height: 10px;
      }
    }
  }
  .fix-gnav.is-active {
    transform: translateY(0);
    opacity: 1;
    .logo-mark {
      height: 80px;
    }
  }
  .fix-gnav--inner {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    flex: 1;
  }
}
@include mq(med) {
  .fix-gnav {
    display: none;
  }
  .header--logo img {
    width: auto;
    max-height: 40px;
  }
}

/* gnav
********************************************** */
.gnav {
  @include mq(med, min) {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: vw(24);
    flex: 1;
  }
  @include mq(med) {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 300;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 100px 7% 24px 7%;
    background-color: var(--bg-main2);
    background-image: url(../images/common/ptn04.jpg);
    overflow: scroll;
    pointer-events: none;
    opacity: 0;
    transition: var(--transit-default, all 0.4s ease-out);
    -webkit-overflow-scrolling: touch;
    &::before,
    &::after {
      content: "";
      position: absolute;
    }
    &::before {
      background-image: url(../images/common/gnav-bg01-sp.png);
    }
    &::after {
      background-image: url(../images/common/gnav-bg02-sp.png);
    }
  }
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}
.gnav a {
  display: flex;
  @include center-flex;
}
.gnav--menu {
  font-size: rem(18);
  justify-content: flex-end;
  @include mq(med, min) {
    margin-right: calc(-1.5em - 0.06em);
  }
  @include mq(med) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 1rem;
    padding-block: 1rem;
    color: var(--clr-wht);
    font-size: sprem(16);
    border-block: 1px solid rgba(255, 255, 255, 0.5);
  }
}
.gnav--menu li {
  position: relative;
  display: grid;
  place-items: center;
  @include mq(med) {
    display: block;
    width: 100%;
  }
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 5px / 2);
    width: 5px;
    height: 5px;
    display: block;
    background-color: currentColor;
    transform: rotate(45deg);
    @include mq(med, min) {
      right: calc(-5px / 2);
    }
    @include mq(med) {
      left: 0;
    }
  }
  @include mq(med, min) {
    &:last-child::after {
      display: none;
    }
  }
}
.gnav--menu a {
  display: block;
  @include mq(sp) {
    color: var(--clr-wht);
  }
}
.gnav--link {
  padding: 0 1.5em;
  @include mq(sp, min) {
    display: flex;
    align-items: center;
    text-align: center;
  }
  @include mq(sp) {
    padding: 1em;
    line-height: 1.5;
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
  }
}
.gnav--link span {
  position: relative;
  display: block;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--bg-main);
    transform: scaleX(0);
    transform-origin: right;
    transition: var(--transit-default);
    transition-property: transform;
  }
}
.head_sub_nav {
  position: relative;
  @include mq(med, min) {
    background-color: #fff;
  }
  @include mq(med) {
    padding-left: 32px !important;
  }
}
.head_sub_nav--wrap {
  position: absolute;
  z-index: 100;
  display: block;
  width: 100%;
  transition: var(--transit-default, all 0.4s ease-out);
  opacity: 0;
  pointer-events: none;
  @include mq(med) {
    position: relative;
    border-top: 1px dotted var(--clr-main, #1d7fb7);
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
}
.head_sub_nav li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.head_sub_nav a {
  display: block;
  padding: 16px 24px;
}
.menu-item-has-children {
  position: relative;
}

// gnav--sub
.gnav--sub {
  @include mq(sp, min) {
    margin-bottom: 0.3rem;
  }
  @include mq(med) {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    color: var(--clr-wht);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
}
.sub-menu {
  margin-inline: -1em;
  font-size: rem(14);
  line-height: 1;
  @include mq(med, min) {
    font-size: rem(12);
    justify-content: flex-end;
  }
}
.sub-menu a {
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 0.5em 0.7em;
  @include mq(sp, min, ps) {
    @include mq(pc) {
      letter-spacing: 0.1em;
    }
  }
  @include mq(med) {
    color: var(--clr-wht);
    padding: 0.5em 1em;
  }
  &::before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    border-right: 1px solid;
    border-top: 1px solid;
    transform: rotate(52deg) skewX(10deg);
  }
  &:hover {
    opacity: 0.7;
  }
}

// gnav--other
.gnav--other {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  @include mq(sp, min) {
    margin-bottom: 1.5rem;
    padding-top: 5px;
  }
  @include mq(sp) {
    flex-direction: column;
  }
}

// gnav--search
@include mq(sp) {
  .gnav--search {
    width: 100%;
  }
}
.gnav--search input {
  height: 29px;
  padding: 0.15em 1em;
  font-size: rem(12);
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  background: none;
  border: 1px solid currentColor;
  line-height: var(--line-height-none);
  @include mq(sp, min) {
    width: 9.5rem;
  }
  @include mq(sp) {
    width: 100%;
    height: 32px;
    color: var(--clr-wht);
  }
}
.gnav--search input::placeholder {
  color: currentColor;
  opacity: 0.5;
}
.gnav--search-btn {
  position: absolute;
  right: calc(14px / 2);
  top: calc(50% - 14px / 2);
  width: 14px;
  height: 14px;
  border: none;
  background: none;
  @include mq(sp) {
    color: var(--clr-wht);
  }
  span {
    display: block;
    text-indent: 100%;
    overflow: hidden;
    letter-spacing: 0;
    white-space: nowrap;
  }
  svg {
    position: absolute;
    inset: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    @include mq(sp) {
      fill: var(--clr-wht);
    }
  }
}

// gnav--language
.gnav--language {
  position: relative;
  z-index: 1;
  max-width: 150px;
  background-color: var(--bg-blk);
  border-radius: 13px;
  &::before {
    content: "";
    position: absolute;
    right: 10px;
    top: calc(50% - rem(8) / 2);
    width: rem(8);
    height: rem(8);
    color: #fff;
    border-right: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(45deg);
  }
  @include mq(sp) {
    margin-bottom: 2rem;
  }
}
.gnav--language .gt_selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 26px;
  padding-inline: 1.3em;
  color: #fff;
  font-size: rem(13);
  line-height: 1;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  border-radius: 13px;
  appearance: none;
}
.win .gnav--language .gt_selector option {
  color: #000;
}
@media (hover: hover) {
  .gnav--language-lists:hover .gnav--language-list {
    transform: scaleY(1);
    opacity: 1;
  }
}
@media (hover: none) {
  .gnav--language-btn:hover + .gnav--language-list {
    transform: scaleY(1);
    opacity: 1;
  }
}
.gnav--language-list {
  position: absolute;
  left: 0;
  top: 1rem;
  z-index: -1;
  width: 100%;
  padding-top: 1rem;
  background-color: var(--bg-blk);
  border-radius: 0 0 13px 13px;
  transform: scaleY(0);
  opacity: 0;
  transition: var(--transit-default);
  transform-origin: top;
  @include mq(sp) {
    padding-top: 1.5rem;
  }
}
.gnav--language-list a {
  color: var(--clr-wht);
  padding: 0.5em;
  cursor: pointer;
  @include mq(sp) {
    padding: 0.8em;
  }
  &:hover {
    opacity: 0.7;
  }
}

// gnav--contact
.gnav--contact {
  font-size: rem(14);
  letter-spacing: var(--ltr-space-default);
  line-height: var(--line-height-none);
}

// gnav_btn ===================================
.gnav_btn {
  display: none;
}
@include mq(med) {
  .gnav_btn {
    position: fixed;
    top: 0;
    right: spvw(10);
    display: grid;
    place-items: center;
    width: 64px;
    height: 64px;
    z-index: 9999;
    transition: var(--transit-default, all 0.4s ease-out);
  }
  .gnav_btn--lines {
    position: relative;
    width: 30px;
    height: 15px;
    margin-bottom: 1em;
  }
  .gnav_btn--lines span {
    transition: var(--transit-default, all 0.4s ease-out);
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 7px;
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
  .gnav_btn--txt {
    position: absolute;
    bottom: -1.5em;
    left: 50%;
    font-size: 10px;
    font-weight: bold;
    font-family: var(--font-en);
    line-height: 1;
    text-indent: 0.16em;
    white-space: nowrap;
    transform: translateX(-50%);
  }
  .active {
    .gnav_btn {
      color: var(--clr-wht);
    }
    .gnav_btn--lines span {
      &:nth-of-type(1) {
        transform: translateY(7px) rotate(-25deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translateY(-7px) rotate(25deg);
      }
    }
  }
}

@include mq(med, min, ps) {
  .gnav,
  .gnav--menu li {
    height: 100%;
  }
  .gnav_sub_toggle {
    display: none;
  }
  .gnav--contact a {
    border-radius: 0;
  }
  /* hover */
  .gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover span,
  .gnav--menu > li.current-menu-item > .gnav--link span {
    &::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
  .head_sub_nav a:hover {
    opacity: 0.7;
  }
}
@include mq(med) {
  .menu-item-has-children.active .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .gnav_sub_toggle {
    position: absolute;
    top: 0;
    right: -20px;
    width: 64px;
    height: 64px;
    transition: var(--transit-default, all 0.4s ease-out);
    color: var(--clr-main, #1d7fb7);
  }
  .gnav_sub_toggle::before,
  .gnav_sub_toggle::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 11px;
    height: 1px;
    border-bottom: 1px solid;
  }
  .gnav_sub_toggle::after {
    transform: rotate(90deg);
  }
  .gnav_sub_toggle.active {
    transform: rotate(180deg);
  }
}

// TOP
@include mq(sp, min) {
  .home {
    .header--logo.head {
      transform: translateY(46px);
      .logo-mark {
        display: none;
      }
      .logo-mark-wh {
        display: block;
        position: relative;
        opacity: 1;
      }
      svg {
        fill: var(--clr-wht) !important;
      }
    }
    .gnav .gnav--menu,
    .gnav .gnav--menu a,
    .gnav .gnav--sub a {
      color: var(--clr-wht);
    }
  }
}
