@use "../abstracts" as *;

/* lps_parts--history
********************************************** */
.lps_parts--history .lps_parts--child {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: rem(120);
    inset-block: vw(-40);
    width: 1px;
    background-color: var(--border-main);
    @include mq(sp) {
      left: sprem(70);
    }
  }
}
.lps_parts--history .history_item:not(:last-child) {
  margin-bottom: 40px;
  @include mq(sp) {
    margin-bottom: 16px;
  }
}
.lps_parts--history .ttl-03 {
  position: relative;
  margin-bottom: 0 !important;
  color: var(--clr-main);
  font-size: rem(28);
  font-weight: normal;
  letter-spacing: 0.04em;
  line-height: var(--line-height-none);
  @include mq(sp) {
    font-size: sprem(20);
  }
  &::before {
    content: "";
    position: absolute;
    top: 0.3em;
    right: calc(-12px / 2);
    width: 12px;
    height: 12px;
    background-color: var(--border-main);
    border-radius: 50%;
    @include mq(sp) {
      right: calc(-8px / 2);
      width: 8px;
      height: 8px;
    }
  }
  span {
    width: auto;
    transform: none;
  }
}
.lps_parts--history .text {
  display: grid;
  grid-template-columns: rem(120) 1fr;
  @include mq(sp) {
    grid-template-columns: sprem(70) 1fr;
  }
}
.lps_parts--history .mce-content-body {
  position: relative;
  padding-left: rem(40);
  @include mq(sp) {
    padding-left: sprem(20);
  }
}
.lps_parts--history .mce-content-body .editor-ttl2 {
  margin-top: 0;
}
