@use "../abstracts" as *;

/* ##############################################################################

  FORMY

############################################################################## */
.form {
  padding: vw(140) vw(200);
  background-color: var(--bg-wht, #fff);
  box-shadow: 0 0 24px rgba(93, 93, 93, 0.05);
  @include mq(sp) {
    padding: spvw(20);
  }
}
#formy_form table {
  width: 100%;
}
#formy_form th,
#formy_form td {
  padding: rem(16);
  vertical-align: top;
  border-bottom: solid 1px rgb(193 187 170 / 30%);
  border: none;
  @include mq(sp) {
    display: block;
    width: auto !important;
    @include p-lr(0);
  }
}
#formy_form th {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
  @include mq(sp, min, ps) {
    width: 30%;
  }
  @include mq(sp) {
    width: 100%;
    border-bottom: none;
    padding-bottom: 0;
    white-space: normal;
    font-weight: bold;
  }
}
#formy_form table input[type="text"],
#formy_form table input[type="email"],
#formy_form table input[type="tel"],
#formy_form table input[type="date"],
#formy_form table input[type="password"] {
  height: 2.5rem;
}
#formy_form table input[type="text"],
#formy_form table input[type="email"],
#formy_form table input[type="tel"],
#formy_form table input[type="date"],
#formy_form table input[type="password"],
#formy_form table input[type="file"],
#formy_form table textarea {
  width: 100%;
  border-radius: rem(5);
  vertical-align: bottom;
}
#formy_form table input[type="text"],
#formy_form table input[type="email"],
#formy_form table input[type="tel"],
#formy_form table input[type="date"],
#formy_form table input[type="password"],
#formy_form select,
#formy_form textarea {
  margin: 0;
  padding: rem(5) rem(15);
  border: 1px solid var(--border-main);
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  font-size: 1rem;
  transition: var(--transit-default, all 0.4s ease-out);
  @include mq(sp) {
    font-size: 16px;
  }
}
#formy_form textarea {
  height: rem(100);
}
#formy_form select {
  height: rem(40);
}
#formy_form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#formy_form input:hover {
  opacity: 0.7;
}
#formy_form textarea:hover {
  opacity: 0.7;
}
#formy_form input:focus {
  outline: none;
}
#formy_form .parsley-validated {
  background-color: var(--border-main);
}
#formy_form .parsley-error {
  background-color: #fee;
}
#formy_form .parsley-success {
  background-color: #fff;
}
.help_text {
  font-size: rem(14);
  color: #999;
}
.hidden_help {
  display: none;
}
.formy_privacy div {
  overflow-y: scroll;
  height: rem(140);
  border: solid 1px #ccc;
  font-size: rem(14);
  padding: rem(8) rem(16);
}
.requiredIcon {
  background-color: #f55555;
  color: #fff;
  margin: 0 0 0 1em;
  font-size: 0.75rem;
  padding: 0.5em 1em;
  float: right;
  line-height: 1;
}
#formy_btn {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-top: rem(32);
  text-align: center;
}
#formy_btn input {
  font-size: inherit;
  border: none;
  cursor: pointer;
  padding: rem(16) rem(32);
  width: 90%;
  max-width: 280px;
  -webkit-appearance: none;
  appearance: none;
  font-family: var(
    --font-primary,
    YuGothic,
    "Yu Gothic",
    "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic ProN",
    sans-serif
  );
  transition: var(--transit-default, all 0.4s ease-out);
  &:not(:first-child) {
    margin-bottom: rem(24);
  }
  @include mq(sp) {
    width: 100%;
    background-size: contain;
    background-position: center;
  }
}
#formy_form td input[type="radio"],
#formy_form td input[type="checkbox"] {
  display: none !important;
}
#formy_form td .wpcf7-list-item {
  display: block;
}
#formy_form td .wpcf7-list-item-label {
  position: relative;
  display: inline-block;
  padding: rem(8) rem(8) rem(8) rem(40);
  line-height: 1.6;
  vertical-align: top;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
}
#formy_form td .wpcf7-list-item-label:hover {
  opacity: 0.7;
}
#formy_form td .wpcf7-list-item-label::before {
  content: "";
  position: absolute;
  width: rem(16);
  height: rem(16);
  border: 2px solid #788b93;
  left: rem(16);
  top: rem(12);
}
#formy_form td input[type="radio"] + .wpcf7-list-item-label::before {
  border-radius: rem(10);
}
#formy_form td input[type="radio"]:checked + .wpcf7-list-item-label,
#formy_form td input[type="checkbox"]:checked + .wpcf7-list-item-label {
  color: #e75f5f;
  font-weight: bold;
}
#formy_form td input[type="radio"]:checked + .wpcf7-list-item-label::before,
#formy_form td input[type="checkbox"]:checked + .wpcf7-list-item-label::before {
  border-color: #e75f5f;
}
#formy_form td input[type="radio"]:checked + .wpcf7-list-item-label::after,
#formy_form td input[type="checkbox"]:checked + .wpcf7-list-item-label::after {
  content: "";
  width: rem(10);
  height: rem(18);
  top: rem(4);
  left: rem(20);
  border-right: 2px solid #e75f5f;
  border-bottom: 2px solid #e75f5f;
  display: block;
  position: absolute;
  z-index: 10;
  transform: rotate(45deg);
}
.formy_confirm {
  background-color: #4dbaff;
}
.formy_submit_disabled {
  background-color: #ccc;
}
#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}
#formy_btn input.autoConfirmBack {
  position: relative;
  background-color: transparent;
  color: #555;
  padding: rem(8) rem(27);
  min-width: auto;
  font-size: rem(15);
  border: 1px solid #aaa;
  border-radius: rem(32);
}
.formy_send {
  background-color: #ff6600;
}
#total_required {
  padding: rem(16);
  color: #f55555;
  text-align: center;
}

@include mq(sp, min, ps) {
  #formy_form .confirm-message {
    text-align: center;
  }
}
@include mq(sp) {
  #formy_form td {
    padding-top: 0;
  }
  .autoConfirmBack {
    margin-bottom: sprem(8);
  }
}

.formy_confirm,
.wpcf7-confirm {
  background-color: #4dbaff;
}
.formy_submit_disabled {
  background-color: #ccc;
}
#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}
.autoConfirmBack,
.wpcf7-back {
  background-color: #aaa;
}
.formy_send,
.wpcf7-submit {
  color: var(--clr-body);
  background-color: #dbd7c0;
}
#total_required {
  padding: 16px;
  color: #f55555;
  text-align: center;
}
span.wpcf7-not-valid-tip {
  display: inline;
}
.wpcf7c-conf {
  background-color: transparent;
  border: 0 !important;
}
.wpcf7c-conf[type="checkbox"]:checked + input[type="hidden"] + label,
.wpcf7c-conf[type="radio"]:checked + input[type="hidden"] + label {
  padding-left: 15px !important;
  color: #333 !important;
}
.wpcf7c-conf[type="checkbox"]:checked + input[type="hidden"] + label::before,
.wpcf7c-conf[type="checkbox"]:checked + input[type="hidden"] + label::after,
.wpcf7c-conf[type="checkbox"] + label,
.wpcf7c-conf[type="radio"]:checked + input[type="hidden"] + label::before,
.wpcf7c-conf[type="radio"]:checked + input[type="hidden"] + label::after,
.wpcf7c-conf[type="radio"] + label {
  display: none !important;
}
.wpcf7-spinner {
  display: block;
  margin: 16px auto;
}

@media print, screen and (min-width: 768px) {
  #formy_btn .wpcf7-form-control:not(:last-child) {
    margin-right: 6px;
  }
}
@media screen and (max-width: 767px) {
  .autoConfirmBack,
  #formy_btn .wpcf7-form-control:not(:last-child) {
    margin-bottom: 8px;
  }
}

/* リキャプチャ・キャプション
**************************************** */
.grecaptcha-badge {
  visibility: hidden;
}
.recaptcha_policy {
  padding: 0;
  margin-top: 2rem;
  color: #333;
  font-size: 0.625rem; /* 10px */
  text-align: center;
  letter-spacing: 0;
}
.recaptcha_policy a {
  color: var(--clr-main);
  font-size: 0.75rem; /* 12px */
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .recaptcha_policy {
    text-align: left;
  }
}

#formy_form input[name="postcode"],
#formy_form input[name="prefectures"] {
  width: 7em !important;
}
