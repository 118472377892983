@use "../abstracts" as *;

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: 8px;
}
.btn a {
  position: relative;
  z-index: 1;
  display: block;
  padding: 0.8em 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(14);
  width: fit-content;
  min-width: min(300px, 100%);
  color: var(--btn-clr-main);
  text-align: center;
  text-decoration: none;
  line-height: 1.5;
  background-color: var(--btn-bg-main);
  @include mq(sp) {
    @include auto-margin;
    justify-content: center;
  }
  &::before {
    content: "";
    display: block;
    @include rect(8);
    border: 1px solid;
    transform: rotate(45deg);
  }
}
.flex > .btn a {
  min-width: 100%;
}
.btn.txt-ctr a {
  @include auto-margin;
}
.btn a:hover {
  color: var(--btn-clr-wht);
  background-color: var(--btn-bg-main-hvr);
}
.btn.btn-next_page a {
  min-width: 100%;
  border-radius: 0;
  text-align: left;
}

/* --- btn-line --- */
.btn-line a {
  justify-content: flex-start;
  text-align: left;
  background-color: var(--btn-bg-line);
  border-inline: 1px solid currentColor;
  &::before {
    background-color: currentColor;
  }
}
.btn-line a:hover {
  color: inherit;
  background-color: var(--btn-bg-line-hvr);
}

/* --- btn-sm --- */
.btn-sm a {
  justify-content: center;
  min-width: min(200px, 100%);
  padding: 0.25em;
  line-height: var(--line-height-none);
  &::before {
    @include rect(6);
  }
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--btn-clr-wht, #333);
}
.btn-wh svg {
  fill: var(--btn-clr-wht, #333);
}
.btn-wh a:hover {
  color: var(--btn-clr-wht-hvr, #fff);
  background-color: var(--btn-bg-wht-hvr, #333);
}
.btn-wh a:hover svg {
  fill: var(--btn-clr-wht-hvr, #fff);
}
@include mq(sp) {
  .flex > .btn:not(:last-child) {
    margin-bottom: 8px !important;
  }
}

/* more_txt
********************************************** */
.more_txt {
  position: absolute;
  bottom: 1.5rem;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: var(--clr-wht);
  font-size: rem(12);
  line-height: var(--line-height-none);
  transition: var(--transit-default);
  letter-spacing: var(--ltr-space-default);
  &::before {
    content: "";
    width: 6px;
    height: 6px;
    border: 1px solid;
    transform: rotate(45deg);
  }
}
li:hover .more_txt {
  opacity: 1;
}
