@use "../abstracts/" as *;

/* home_news
********************************************** */
.home_news {
  position: relative;
  padding-top: 0;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    background-color: rgb(0 0 0 / 60%);
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 20px;
    background-image: url(../images/common/img_gallery--deco.png);
    background-position: top;
    background-size: auto 100%;
    @include mq(sp) {
      height: 10px;
    }
  }
}
.home_news .ttl-01 {
  position: relative;
  margin-bottom: 2em;
  margin-top: calc(-28px / 2);
  padding-top: 2em;
  font-size: rem(32);
  letter-spacing: 0.32em;
  @include mq(sp) {
    font-size: sprem(24);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: none;
    border-radius: 50%;
    border: 1px solid var(--bg-main);
  }
  &::before {
    top: 0;
    left: calc(50% - 28px / 2);
    width: 28px;
    height: 28px;
  }
  &::after {
    top: calc((28px - 22px) / 2);
    left: calc(50% - 22px / 2);
    width: 22px;
    height: 22px;
  }
}
.home_news .inner {
  max-width: 800px;
}
.home_news .ttl-01 span {
  width: fit-content;
  @include write-v;
}
