@use "../abstracts" as *;

/* lps_parts--img_gallery
********************************************** */
.lps_parts--img_gallery {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 696px;
  margin-left: calc(50% - 50vw);
  color: var(--clr-wht);
  @include mq(sp) {
    height: max(calc(280 / 414 * 100vw), 380px);
  }
}
.lps_parts--img_gallery .lps_parts--child {
  width: fit-content;
  height: 100%;
}
.lps_parts--img_gallery .img_gallery--bg {
  position: absolute;
  inset-inline: 0;
  inset-block: 30px;
  z-index: 0;
  background-image: var(--bg);
  background-position: center;
  background-size: cover;
  overflow: hidden;
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100vw;
    height: 20px;
    background-image: url(../images/common/img_gallery--deco.png);
    background-position: bottom;
    background-size: auto 100%;
    @include mq(sp) {
      height: 10px;
    }
  }
  &::before {
    top: 0;
    transform: scale(1, -1);
  }
  &::after {
    bottom: 0;
  }
}
.lps_parts--img_gallery .img_gallery--ttl {
  order: 3;
  margin-top: vw(170);
  margin-right: vw(220);
  font-size: rem(30);
  @include mq(sp) {
    margin-top: spvw(100);
    margin-right: spvw(50);
    font-size: sprem(16);
  }
}
.lps_parts--img_gallery .img_gallery--ttl span {
  @include write-v;
  -webkit-mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 40%);
}
.lps_parts--img_gallery .img_gallery_list--wrap {
  display: flex;
  align-items: center;
  height: 100%;
}
.lps_parts--img_gallery .img_gallery_list {
  position: relative;
  z-index: 0;
  display: flex;
  height: 100%;
  padding-right: 100px;
  @include mq(sp) {
    padding-right: 70px;
  }
}
.lps_parts--img_gallery .img_gallery_item {
  position: relative;
  flex-shrink: 0;
  order: 4;
  width: 490px;
  height: 350px;
  font-size: rem(12);
  @include mq(sp) {
    width: 170px;
    height: 170px;
  }
  &:not(:last-child):not(:only-child) {
    margin-right: 100px;
    @include mq(sp) {
      margin-right: 70px;
    }
  }
}
.lps_parts--img_gallery .img_gallery_item--cont {
  position: absolute;
  inset: 0;
  width: fit-content;
  text-align: center;
}

.lps_parts--img_gallery .img_gallery_item--cont img {
  width: fit-content;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  -webkit-mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 10%, rgb(0, 0, 0) 20%);
}
.lps_parts--img_gallery .img_gallery_item--cont .text {
  position: absolute;
  left: calc(100% - 3em);
  white-space: nowrap;
  @include mq(sp) {
    font-size: sprem(8);
    font-weight: normal;
    letter-spacing: 0.1em;
  }
}
.lps_parts--img_gallery .img_gallery_item--cont .ttl {
  margin-bottom: 1em;
  font-size: rem(14);
}
.lps_parts--img_gallery .img_gallery_item {
  &:nth-of-type(1) {
    order: 1;
  }
  &:nth-of-type(2) {
    order: 2;
  }
  &:nth-of-type(4n + 1) {
    .img_gallery_item--cont .text {
      bottom: 5em;
    }
  }
  &:nth-of-type(4n + 2) {
    margin-top: 330px;
    @include mq(sp) {
      margin-top: 170px;
    }
    .img_gallery_item--cont .text {
      top: 3em;
    }
  }
  &:nth-of-type(4n + 3) {
    margin-top: 90px;
    @include mq(sp) {
      margin-top: 65px;
    }
    .img_gallery_item--cont .text {
      bottom: 5em;
    }
  }
  &:nth-of-type(4n) {
    margin-top: auto;
    @include mq(sp, min) {
      margin-bottom: -30px;
    }
    .img_gallery_item--cont .text {
      top: 3em;
    }
  }
}

.home {
  .lps_parts--img_gallery {
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: -1;
      background-color: rgb(0 0 0 / 60%);
    }
    .ptn01 {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 30px;
      &:first-child {
        top: 0;
        bottom: auto;
      }
    }
  }
}
