@use "../abstracts/" as *;

/* home_ranking
********************************************** */
.home_ranking {
  position: relative;
  margin-top: vw(-272);
  padding-top: vw(272);
  overflow: hidden;
}
.home_ranking .inner {
  position: relative;
  z-index: 2;
  max-width: 1620px;
  padding-block: vw(100) vw(155);
  @include mq(sp) {
    width: 95%;
    padding-block: spvw(30) spvw(70);
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    background-image: url(../images/ranking-bg.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
    filter: drop-shadow(0 0 76px rgba(124, 124, 110, 0.05));
    @include mq(sp) {
      background-image: url(../images/ranking-bg-sp.png);
    }
  }
  .deco04 {
    top: vw(200);
    left: vw(-30);
  }
  .deco05 {
    bottom: vw(290);
    right: vw(-50);
  }
  .deco-circle01 {
    top: vw(-272);
    right: vw(-284);
    z-index: -2;
  }
  .deco-circle02 {
    bottom: vw(-185);
    left: vw(-282);
    z-index: -2;
  }
}
.ranking_list--wrap {
  width: 90%;
  max-width: 1354px;
  margin-inline: auto;
}
.ranking_list {
  display: flex;
  gap: rem(32);
  @include mq(sp) {
    gap: sprem(16);
    flex-direction: column;
  }
}
.ranking_list--others {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: rem(32);
  .rank {
    width: calc(50% - rem(16));
    @include mq(sp) {
      width: 100%;
    }
  }
}
.ranking_list .rank {
  display: flex;
  border: 1px solid var(--border-main);
  flex-direction: column;
  @include mq(sp) {
    flex-direction: row-reverse;
    a {
      width: 50%;
    }
  }
}
.ranking_list--ttl {
  position: relative;
  display: flex;
  align-items: center;
  letter-spacing: 0.14em;
  @include mq(sp, min) {
    height: 40px;
  }
  @include mq(sp) {
    padding-top: 36px;
    font-size: sprem(14);
  }
}
.ranking_list--ttl .txt-extended {
  padding-left: calc(rem(64) + 1.3em);
  padding-right: 0.5em;
  @include mq(sp, min) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.ranking_no {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: rem(8);
  width: rem(64);
  height: 100%;
  color: var(--clr-wht);
  font-size: rem(16);
  font-weight: normal;
  line-height: var(--line-height-none);
  background-color: var(--bg-main);
  @include mq(sp) {
    position: absolute;
    left: 0;
    top: 0;
    width: sprem(40);
    height: 36px;
    font-size: sprem(16);
  }
  &::before {
    opacity: 0.3;
    width: rem(16);
    height: rem(16);
    background-size: cover;
    margin-bottom: 0 !important;
    @include mq(sp) {
      display: none;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: -1%;
    right: -25px;
    width: 26px;
    height: 101%;
    background-color: var(--bg-main);
    clip-path: polygon(0 0, 100% 0, 0 100%);
  }
}
.ranking_list--img {
  position: relative;
  overflow: hidden;
  flex: 1;
  order: -1;
  background-color: var(--clr-body);
  @include mq(sp) {
    width: 50%;
    flex-shrink: 0;
  }
  .no-image {
    width: 80%;
  }
}

.ranking_list--img img {
  transition: var(--transit-default);
}
.ranking_list li:hover img {
  transform: scale(1.05);
  opacity: 0.5;
}

// first ========================
.rank.first {
  flex-direction: column;
  @include mq(sp, min) {
    width: min(vw(600), 600px);
  }
  a {
    width: 100%;
  }
  .ranking_list--ttl {
    padding-top: 0;
    order: 1;
    font-size: rem(20);
    @include mq(sp) {
      height: 36px;
      font-size: sprem(16);
    }
  }
  .ranking_list--img {
    width: 100%;
  }
  .ranking_no {
    @include mq(sp, min) {
      font-size: rem(18);
    }
  }
}

// others ========================
@include mq(sp) {
  .rank.others {
    .ranking_list--ttl .txt-extended {
      padding: 0.5em 1em 1em;
    }
  }
}
