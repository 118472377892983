@use "../abstracts" as *;

/* deco
********************************************** */
.deco {
  position: absolute;
  pointer-events: none;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-size: cover;
  }
}
.deco-balloon {
  &::before {
    animation-name: deco_balloon;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-fill-mode: forwards;
    transform-origin: top center;
  }
}
.deco01 {
  width: max(vw(105), 75px);
  aspect-ratio: 105/206;
  &::before {
    background-image: url(../images/common/deco01.png);
  }
}
.deco02 {
  width: max(vw(111), 77px);
  aspect-ratio: 111/204;
  &::before {
    background-image: url(../images/common/deco02.png);
    animation-delay: -1.5s;
  }
}
.deco03 {
  width: max(vw(131), 80px);
  aspect-ratio: 131/237;
  &::before {
    background-image: url(../images/common/deco03.png);
  }
}
.deco04 {
  width: max(vw(151), 80px);
  aspect-ratio: 151/63;
  &::before {
    background-image: url(../images/common/deco04.png);
    animation: deco_cloud 4s ease-in-out alternate infinite forwards;
  }
}
.deco05 {
  width: vw(127);
  aspect-ratio: 127/88;
  &::before {
    background-image: url(../images/common/deco05.png);
  }
}
.deco06 {
  width: max(vw(160), 121px);
  aspect-ratio: 160/86;
  &::before {
    background-image: url(../images/common/deco06.png);
  }
}
.deco07 {
  width: max(vw(123), 123px);
  aspect-ratio: 123/34;
  animation: deco_cloud 4s ease-in-out alternate infinite forwards;
  &::before {
    background-image: url(../images/common/deco07.png);
  }
}
.deco-dots-line {
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    inset-block: calc(50% - 9px / 2);
    width: 60px;
    background-image: url(../images/common/dots.svg);
    aspect-ratio: 60/9;
  }
  &::before {
    left: -124px;
  }
  &::after {
    right: -124px;
  }
}

.deco-diamond {
  &::before {
    content: "";
    display: block;
    flex-shrink: 0;
    width: rem(32);
    height: rem(32);
    margin-bottom: rem(32);
    background-image: url(../images/common/deco-diamond01.png);
    background-size: cover;
    @include mq(sp) {
      width: sprem(24);
      height: sprem(24);
      margin-bottom: sprem(16);
    }
  }
  &.deco-diamond-sm::before {
    width: rem(23);
    height: rem(23);
    margin-bottom: rem(23);
  }
  &.deco-diamond-wh::before {
    background-image: url(../images/common/deco-diamond01-wh.svg);
  }
}
.lps_sec .ttl-01.deco-diamond::before,
.deco-diamond.txt-ctr::before,
.ttl-01.txt-ctr .txt-extended {
  margin-inline: auto;
}

.deco-circle {
  position: absolute;
  z-index: -1;
  width: vw(717);
  aspect-ratio: 1;
  background-size: contain;
}
.deco-circle01 {
  background-image: url(../images/common/deco-circle01.png);
}
.deco-circle02 {
  background-image: url(../images/common/deco-circle02.png);
}

.feature_posts {
  position: relative;
  &:not(:last-child)::after {
    content: "";
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 6px;
    border-top: 1px solid #c7c39e;
    border-bottom: 1px solid #c7c39e;
  }
}
