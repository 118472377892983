@use "sass:map";

// SASS用の基本設定変数
$fz-pc: 16;
$fz-sp: 13;
$wid-pc: 1920;
$wid-sp: 414;

// 色
$clr-main: #1d7fb7;

// font-families
$font-primary: "ヒラギノUD明朝", "ヒラギノUD明朝 W4 JIS2004";
$font-jp: "秀英にじみ明朝 L";
$font-en: "Montserrat";
$font-en2: "Libre Baskerville";

// CSS変数
:root {
  // typography
  --ltr-space-default: 0.16em;
  --line-height-default: 2;
  --line-height-hdr: 1.5;
  --line-height-none: 1;

  // font-families
  --font-primary: "ヒラギノUD明朝", "ヒラギノUD明朝 W4 JIS2004";
  --font-jp: "秀英にじみ明朝 L";
  --font-en: "Montserrat";
  --font-en2: "Libre Baskerville";

  // font-sizes
  --fz-primary: 16;
  --fz-half: 0.5rem;

  // text colors
  --clr-main: #92835a;
  --clr-body: #29262d;
  --clr-link: #333;
  --clr-wht: #fff;
  --clr-blk: #000;

  // border colors
  --border-main: #c1bbaa;
  --border-sub: #c8c4a6;

  // background colors
  --bg-body: #f2f0e2;
  --bg-main: #92835a;
  --bg-main2: #a9a585;
  --bg-sub: #f0eee1;
  --bg-off_wht: #f4f3ed;
  --bg-wht: #fff;
  --bg-blk: #000;

  //button colors
  --btn-bg-main: #dbd7c0;
  --btn-bg-main-hvr: #92835a;

  --btn-bg-line: transparent;
  --btn-bg-line-hvr: #dbd7c0;
  --btn-clr-line: #29262d;

  --btn-clr-wht: #fff;
  --btn-bg-wht: #fff;
  --btn-clr-wht-hvr: #fff;
  --btn-bg-wht-hvr: #dbd7c0;

  // border colors
  --brd-main: #1d7fb7;

  // animation
  --transit-default: all 0.4s ease-out;

  // formy settings
}

// SASS Maps

$z-index: (
  "header": 9999,
  "top": 9990,
  "aside": 75,
  "middle": 50,
  "main": 1,
  "base": 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160,
  // 2560px
  lg: 120,
  // 1920px
  pc: 85.375,
  // 1366px
  med: 67.5,
  // 1080px
  sp: 47.9375,
  // 767px
  sm: 40,
  // 640px
  xs: 34.375,
  // 500px
  se: 21.25,
  // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$colors: (
  "main": (
    100: #92835a,
    200: #c1bbaa,
    300: #c8c4a6,
    400: #f2f0e2,
    500: #dedcce,
  ),
);

$aspect-ratios: (
  widescreen: "3/2",
  square: "1/1",
  portrait: "2/3",
);
