@use "../abstracts" as *;

/* bg
********************************************** */
.ptn01,
.lps_sec:nth-child(2n + 1) {
  background-color: var(--bg-body);
  background-image: url(../images/common/ptn01.jpg);
}
.ptn02,
.lps_sec:nth-child(2n) {
  background-color: var(--bg-off_wht);
  background-image: url(../images/common/ptn02.jpg);
}
.bg-sub {
  background-color: #eee;
}
.bg-wh {
  background-color: var(--bg-wht);
  background-image: url(../images/common/ptn03.jpg);
}
.bg-main {
  background-color: var(--bg-main);
}
.bg-main2 {
  background-color: var(--bg-main2);
  background-image: url(../images/common/ptn04.jpg);
}
