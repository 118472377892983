@use "../abstracts" as *;

/* breadcrumbs
**************************************** */
.breadcrumbs {
  position: relative;
  width: 100%;
  margin-inline: 8px;
  padding: 0.5em 0;
  font-size: rem(13);
  text-align: left;
  @include mq(sp, min) {
    margin-bottom: vw(120);
    border-block: 1px solid var(--border-main);
    font-size: sprem(12);
    &::before {
      content: "";
      position: absolute;
      inset-inline: 0;
      inset-block: 2px;
      border-block: 1px solid var(--border-main);
      pointer-events: none;
    }
  }
  @include mq(sp) {
    letter-spacing: 0.05em;
  }
}
@include mq(sp, min) {
  .single:is(.post-type-seeing, .post-type-eat, .post-type-activity, .post-type-buy, .post-type-stay, .post-type-event)
    .breadcrumbs {
    margin-bottom: 0;
  }
}
.breadcrumbs a:hover {
  text-decoration: none;
}
.breadcrumbs .breadcrumb_last {
  display: table-cell;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
