@use "../abstracts" as *;

/* lps_parts--faq
********************************************** */
.lps_parts--faq .faq_item:not(:last-child) {
  margin-bottom: 40px;
  @include mq(sp) {
    margin-bottom: 16px;
  }
}
.lps_parts--faq .ttl-03,
.lps_parts--faq .mce-content-body {
  padding-left: 3.5rem;
}
.lps_parts--faq .ttl-03 {
  margin-bottom: 0 !important;
  padding-bottom: 1.5rem;
  color: var(--clr-body);
  font-size: rem(20);
  @include lh(20, 35);
  font-weight: normal;
  border-bottom: 1px solid var(--border-main);
  span {
    width: auto;
    transform: none;
  }
}
.lps_parts--faq .mce-content-body {
  position: relative;
  padding-top: 1.5rem;
}
.lps_parts--faq .ttl-03::after,
.lps_parts--faq .mce-content-body::after {
  position: absolute;
  left: 0;
  display: block;
  font-size: rem(28);
  font-family: var(--font-en2), serif;
  line-height: var(--line-height-none);
  -webkit-font-smoothing: antialiased;
}
.lps_parts--faq .ttl-03::after {
  content: "Q.";
  top: 0;
  color: var(--clr-main);
}
.lps_parts--faq .mce-content-body::after {
  content: "A.";
  top: 1.5rem;
  color: var(--clr-main);
}
.lps_parts--faq .mce-content-body .editor-ttl2 {
  margin-top: 0;
}
.lps_parts--faq .text img {
  max-width: 248px;
  margin-top: 0;
  margin-right: 40px;
  overflow: hidden;
  @include mq(sp) {
    float: none !important;
    margin-top: 2px;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 8px !important;
    border-radius: 8px;
  }
}
