@use "../abstracts" as *;

/* lps_parts--img_text
********************************************** */
.box:not(.box-wh),
.outline {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    inset: rem(-5);
    border: 1px solid var(--border-sub);
    pointer-events: none;
  }
  .outline::before {
    display: none;
  }
}
.box:not(.box-wh) {
  &::before {
    inset: rem(5);
  }
}
.lps_parts--img_text .img_text--child:only-child,
.lps_parts--img_text .lps_parts--child.bg-wh .img_text--child:only-child {
  width: 100%;
}
.lps_parts--img_text .lps_parts--child .img_text--img:only-child .img {
  display: table;
  @include auto-margin;
}
.lps_parts--img_text .img_text--child .img {
  height: 100%;
}
.lps_parts--img_text .bg-wh .img_text--txt {
  padding: 5%;
}
.lps_parts--img_text .img_text--txt--inner {
  width: 100%;
}
.lps_parts--img_text .text {
  justify-content: flex-start;
}
.lps_parts--img_text .img_text--txt table {
  width: 100%;
}
.lps_parts--img_text .inner-lg .img_text--img {
  position: absolute;
  top: 0;
}
.lps_parts--img_text .inner-lg.bg-wh {
  &::before {
    content: "";
    position: absolute;
    inset-block: 0;
    inset-inline: vw(80);
    z-index: -1;
    background-color: var(--bg-wht);
    background-image: url(../images/common/ptn03.jpg);
    @include mq(sp) {
      inset-inline: 0;
    }
  }
}
@include mq(sp, min) {
  .lps_parts--img_text .inner-lg .img_text--child {
    width: 45.833%;
  }
  .lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--img {
    left: vw(80);
  }
  .lps_parts--img_text .inner-lg.flex-reverse .img_text--img {
    right: vw(80);
  }
  .lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--txt {
    margin-left: auto;
  }
  .lps_parts--img_text .inner-lg.flex-reverse .img_text--txt {
    margin-right: auto;
  }
}
@include mq(sp) {
  .lps_parts--img_text .inner-lg .img_text--img .img::before {
    display: none;
  }
}

@include mq(sp, min, ps) {
  .lps_parts--img_text .inner-lg .img_text--img {
    height: 100%;
  }
  .lps_parts--img_text .inner-lg .img_text--txt {
    display: flex;
    align-items: center;
    min-height: 607.64px;
    width: 41.388%;
  }
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding: 6%;
  }
}
@include mq(sp) {
  .lps_parts--img_text .bg-wh .img_text--txt {
    padding: 24px;
  }
  .lps_parts--img_text .btn {
    text-align: center;
  }
  .lps_parts--img_text .inner-lg .img_text--txt {
    margin-right: auto;
  }
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 40px;
  }
  .lps_parts--img_text.bg-wh + .bg-wh {
    margin-top: 0;
  }
  .lps_parts--img_text .inner-lg .img_text--img {
    position: relative;
  }
  .lps_parts--img_text .inner-lg .img_text--child .img {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
}
